import React, { useMemo } from 'react';
import MainAppLayout, { MainAppLayoutProps } from 'components/templates/MainAppLayout/MainAppLayout';
import { useActivePatient } from 'hooks/useActivePatient';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { RouteKeys } from 'models/RouteModels';
import { useNavigation } from 'hooks/useNavigation';
import useAppointmentLayoutProps from 'hooks/useAppointmentLayoutProps';
import { useProData } from 'hooks/useProData';
import { useXrayData } from 'hooks/useXrayData';
import { FullJoints } from 'models/XrayModels';
import {
  collectRoiIds,
  containerStyles,
  filterAccionPredSectsByFullJoint,
  isMissingXrayData,
  mapClientFavoredPredictionsFromAccionPredSects,
  getThumbnailXray
} from 'helpers/helpers';
import JointOutcomesCard, { JointOutcomesCardLoader } from 'components/organisms/JointOutcomesCard/JointOutcomesCard';
import { Container } from '@mui/material';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Breakpoints } from 'models/StyleModels';
import { useXrayMutations } from 'hooks/useXrayMutations';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDBox from 'components/atoms/MDBox/MDBox';
import colors from 'assets/theme/base/colors';
import { useRoiImageData } from 'hooks/useRoiImageData';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useAuth } from 'hooks/useAuth';
import ActionSlugs from 'services/PermissionsService';

const ExamRoomAllJointsPage: React.FC = () => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const { user } = useAuth();

  const { UPDATE$XRAY } = ActionSlugs;

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const { activePatient } = useActivePatient();

  const {
    appointment,
    appointmentError,
    appointmentLoading,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    preventRefetch: true,
  });

  const {
    latestProsMappedToJoints,
    allAppointmentProsLoading,
    allAppointmentProsError,
  } = useProData({ appointment, options: { preventRefetch: true } });

  const {
    allAppointmentXrays,
    xraysMappedToJoints,
    allAppointmentXraysLoading,
    allAppointmentXraysError,
  } = useXrayData({ appointment });

  const roiIds = useMemo(() => allAppointmentXrays ? collectRoiIds(allAppointmentXrays) : null, [allAppointmentXrays]);

  const { roiImgUrls, roiImagesLoading } = useRoiImageData({
    roiIds,
    options: { preventRefetch: true },
  });

  const initalQueriesLoading = appointmentLoading || allAppointmentProsLoading || allAppointmentXraysLoading;
  const isError = appointmentError || allAppointmentProsError || allAppointmentXraysError;

  const { batchConfirmXrays, updateXrayLoading } = useXrayMutations({ appointment });

  // this function receives a FullJoint, finds the xrays which contain that specific joint
  // (joint name and side of body) via the Accion prediction results, and then finds the
  // region of interest for the given joint in the AP Pelvis view for hips, and the Flexion
  // view for knees



  // const getThumbnailXray = (fullJoint: FullJoints) => {
  //   const xraysForJoint = xraysMappedToJoints[fullJoint];

  //   if (!xraysForJoint || !xraysForJoint.length) return;

  //   const jointSide = getJointSideFromFullJoint(fullJoint);
  //   const joint = getJointFromFullJoint(fullJoint);

  //   const xraysByRoiByJointSide = xraysForJoint.filter((xray) => {
  //     if (jointSide === 'right') {
  //       return xray.prediction_results?.rightROI || xray.prediction_results.singleROI;
  //     }
  //     return xray.prediction_results?.leftROI || xray.prediction_results.singleROI;
  //   });

  //   if (!xraysByRoiByJointSide || !xraysByRoiByJointSide.length) return;

  //   const worstDegenLevelByView = xraysForJoint.map((xray) => ({
  //     clientView: xray.client_provided_view_type,
  //     worst: getWorstCaseDegeneration(xray.prediction_results.sections.map((section) => section.prediction))
  //   }))

  //   const jointOverallWorstDegenLevel = getWorstCaseDegeneration(worstDegenLevelByView
  //     .map((xray) => xray.worst as DegenerationLevels));

  //   const allWorstByView = worstDegenLevelByView.filter((xray) => xray.worst === jointOverallWorstDegenLevel);

  //   if (allWorstByView.length > 1) {

  //     if (joint === 'hip') {
  //       return xraysForJoint
  //         .find((xray) => xray.client_provided_view_type === ViewTypes.AP_PELVIS);
  //     }

  //     const showNonFlexion =
  //       !worstDegenLevelByView.some((xray) => xray.clientView === ViewTypes.FLEX)
  //       && worstDegenLevelByView.some((xray) => xray.clientView === ViewTypes.NON_FLEX
  //         || xray.clientView === ViewTypes.KNEECAP);

  //     return xraysForJoint
  //       .find((xray) => xray.client_provided_view_type === (
  //         showNonFlexion ? ViewTypes.NON_FLEX : ViewTypes.FLEX
  //       ));
  //   }

  //   return xraysForJoint
  //     .find((xray) => xray.client_provided_view_type === allWorstByView[0].clientView);
  // };

  // where to route the user to when they click on the Review button on a given joint card
  const singleJointRouteKeysByFullJoint = {
    [FullJoints.RIGHT_KNEE]: RouteKeys.EXAM_ROOM_RIGHT_KNEE,
    [FullJoints.LEFT_KNEE]: RouteKeys.EXAM_ROOM_LEFT_KNEE,
    [FullJoints.RIGHT_HIP]: RouteKeys.EXAM_ROOM_RIGHT_HIP,
    [FullJoints.LEFT_HIP]: RouteKeys.EXAM_ROOM_LEFT_HIP,
  };

  // this is where we run the logic to render the joint cards for each joint in the exam. we
  // don't show a card for any joint that does not have a completed PRO (pro.scores field present)
  // or all required xrays fully analyzed (xray.prediction_results) present for each required view type
  const jointCards = initalQueriesLoading
    ? Object.entries(FullJoints).map(([fj]) => (
      <JointOutcomesCardLoader
        key={fj}
        displayVertical={breakpointBreached}
      />
    ))
    : Object.entries(appointment?.joints || {})
      .filter(([fullJoint, rank]) => (!!rank
        && !!latestProsMappedToJoints[fullJoint as FullJoints]
        && !!xraysMappedToJoints[fullJoint as FullJoints]
        && !!xraysMappedToJoints[fullJoint as FullJoints]?.length
        && !isMissingXrayData(fullJoint as FullJoints, xraysMappedToJoints[fullJoint as FullJoints] || [])
      ))
      .sort(([, aRank], [, bRank]) => (aRank || 0) - (bRank || 0))
      .map(([fullJoint, rank]) => (
        <JointOutcomesCard
          key={fullJoint}
          hideCTA={!user?.can(UPDATE$XRAY)}
          roiImgUrls={roiImgUrls}
          roiImagesLoading={roiImagesLoading}
          fullJoint={fullJoint as FullJoints}
          priority={rank!}
          pro={latestProsMappedToJoints[fullJoint as FullJoints]!}
          xrays={xraysMappedToJoints[fullJoint as FullJoints]!}
          displayVertical={breakpointBreached}
          allXraysConfirmed={
            !filterAccionPredSectsByFullJoint(
              xraysMappedToJoints[fullJoint as FullJoints]?.map((xray) => xray.prediction_results.sections).flat() || [],
              fullJoint as FullJoints,
            ).find((sectionPrediction) => !sectionPrediction.clientConfirmed)
          }
          handleConfirmXrays={() => batchConfirmXrays(xraysMappedToJoints[fullJoint as FullJoints] || [], fullJoint as FullJoints)}
          confirmRequestLoading={updateXrayLoading}
          jointDegenOutcomes={
            mapClientFavoredPredictionsFromAccionPredSects(
              filterAccionPredSectsByFullJoint(
                xraysMappedToJoints[fullJoint as FullJoints]?.map((xray) => xray.prediction_results.sections).flat() || [],
                fullJoint as FullJoints,
              )
            )
          }
          thumbnailXray={getThumbnailXray(fullJoint as FullJoints, xraysMappedToJoints[fullJoint as FullJoints] || [])}
          handleClickReview={
            () => appointment
              ? navigateToBaseRouteWithAptmtDetails({
                routeKey: singleJointRouteKeysByFullJoint[fullJoint as FullJoints],
                appointmentId: appointment.appointment_id,
                appointmentDateISOString: appointment.appointment_date,
              })
              : {}
          }
        />
      ));

  const handlePrevious = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const handleNext = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.REPORT,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const { headerItems } = useAppointmentLayoutProps({
    appointment,
    patient: activePatient,
    pageTitle: 'Exam Room',
  });

  const mainAppLayoutProps: MainAppLayoutProps = {
    headerItems,
    navActions: {
      previous: {
        text: 'Back',
        action: handlePrevious,
      },
      next: {
        disabled: !jointCards.length || !!jointCards.find((card) => !card.props.allXraysConfirmed),
        text: 'View Report',
        action: handleNext,
      },
    },
  };

  if (!initalQueriesLoading && !appointment) return (
    <ErrorPage code={404} />
  );

  if (isError) return (
    <ErrorPage />
  );

  return (
    <MainAppLayout {...mainAppLayoutProps}>
      {!initalQueriesLoading && !jointCards.length && (
        <MDBox {...containerStyles({
          textAlign: 'center',
          padding: '5% 10%',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        })}>
          <MDTypography
            variant='h3'
            sx={{
              color: colors.grey[600],
              marginBottom: '2rem',
            }}
          >
            Fully analyzed joint outcomes will appear here.
          </MDTypography>
          <MDTypography
            variant='body1'
            sx={{
              color: colors.grey[600]
            }}
          >
            Missing a joint?
            <br />
            You may still need to provide PROs or required X-rays.
          </MDTypography>
        </MDBox>
      )}
      <Container {...containerStyles({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${breakpointBreached ? '100%' : '25rem'}, 1fr))`,
        gap: '.5rem',
      })}>
        {jointCards}
      </Container>
      {!!jointCards.length
        && jointCards.length !== Object.values(appointment?.joints || []).filter((rank) => !!rank).length
        && (
          <MDTypography
            variant='body2'
            sx={{
              color: colors.grey[600],
              width: '100%',
              textAlign: 'center',
              margin: '2.5rem 0 1.5rem 0',
            }}
          >
            Missing a joint? You may still need to provide PROs or required X-rays.
          </MDTypography>
        )
      }
    </MainAppLayout>
  );
};

export default ExamRoomAllJointsPage;
