import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import Container from '@mui/material/Container';
import Footer from "components/molecules/Footer/Footer";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { useOrgData } from "hooks/useOrgData";

import DataTable, { TableColumnType } from "components/organisms/DataTable/DataTable";
import { useAuth } from "hooks/useAuth";
import { Roles, UpdateOrgBody, internalOnlyRoles } from "models/OrgModels";
import MDBox from "components/atoms/MDBox/MDBox";
import MDButton from "components/atoms/MDButton/MDButton";
import { containerStyles, validateEmail } from "helpers/helpers";
import { Breakpoints, MUIColors, Shadows } from "models/StyleModels";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import { useUserData } from "hooks/useUserData";
import { Modal, Tooltip, Typography } from "@mui/material";
import { useBreakpoints } from "hooks/useBreakpoints";
import MDInput from "components/atoms/MDInput/MDInput";
import DropdownSelect from "components/molecules/DropdownSelect/DropdownSelect";
import { postInvite } from "hooks/useInviteMutations";
import { AxiosError } from "axios";
import { DOMAIN_BASE_URL } from "hooks/constants";
import { routesMap } from "data/routes";
import { RouteKeys } from "models/RouteModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faEdit,
  faInfoCircle,
  faPenToSquare,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "components/atoms/Alert/Alert";
import ActionSlugs, { IfUserIsAllowed } from "services/PermissionsService";
import { useOrgMutations } from "hooks/useOrgMutations";
import { UserWithRole } from "models/UserModels";
import { useOrgRoleMutations } from "hooks/useOrgRoleMutations";
import LogoUploadPortal from "components/molecules/LogoUploadPortal/LogoUploadPortal";
import './OrganizationPage.css';
import ProfileContainer from "components/molecules/ProfileContainer/ProfileContainer";
import Switch from "components/atoms/Switch/Switch";
import { useFeatureFlags } from "contexts/FeatureFlagContext";

const SectionHeader: React.FC = ({ children }) => (
  <MDBox
    shadow={Shadows.SMALL}
    borderRadius={borders.borderRadius.lg}
    {...containerStyles({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      marginBottom: '1rem',
      backgroundColor: colors.disabled.main,
      height: '4.5rem',
    })}
  >
    {children}
  </MDBox>
);

const OrganizationPage: React.FC = () => {
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.SMALL });

  const { triageFlow } = useFeatureFlags();

  const { user, accessToken } = useAuth();

  const {
    CREATE$INVITE,
    UPDATE$ORG,
    DELETE$ROLE,
    UPDATE$ROLE,
  } = ActionSlugs;

  const {
    org,
    orgLoading,
    orgError,
    refetchOrg,
  } = useOrgData({ preventRefetch: true });

  const {
    updateOrg,
    updateOrgLoading,
    updateOrgError,
    updateOrgSuccessful,
    uploadLogoError,
  } = useOrgMutations();

  const {
    deleteOrgRole,
    deleteOrgRoleLoading,
    deleteOrgRoleError,
    deleteOrgRoleSuccessful,
    updateOrgRole,
    updateOrgRoleError,
    updateOrgRoleLoading,
    updateOrgRoleSuccessful,
  } = useOrgRoleMutations();

  const {
    usersWithRoles,
    usersWithRolesLoading,
    usersWithRolesError,
  } = useUserData();

  const dataLoading = usersWithRolesLoading || orgLoading;
  const dataError = usersWithRolesError || orgError;
  const mutationSuccessful = updateOrgSuccessful || deleteOrgRoleSuccessful || updateOrgRoleSuccessful;
  const mutationUnsuccessful = !!updateOrgError || !!deleteOrgRoleError || !!updateOrgRoleError || !!uploadLogoError;

  const [lastSuccessfulMutation, setLastSuccessfulMutation] = useState<'updateOrg' | 'deleteOrgRole' | 'updateOrgRole' | null>(null);

  // either the "remove team member" modal is open for this user, or
  // the edit role dropdown is open for them
  const [userInEditMode, setUserInEditMode] = useState<UserWithRole | null>(null);

  // if updating a user's role via the dropdown, the new selection is saved in state here
  const [newUserRole, setNewUserRole] = useState<Roles | null>(null);
  const resetNewRole = () => setNewUserRole(null);

  interface InviteDetails {
    email: string;
    role: Roles;
  }

  const defaultInviteValues = {
    email: '',
    role: Roles.USER,
  };

  const [inviteDetails, setInviteDetails] = useState<InviteDetails>(defaultInviteValues);
  const updateInviteDetails = (key: keyof InviteDetails, value: string | Roles) => setInviteDetails({
    ...inviteDetails,
    [key]: value,
  });
  const resetInviteDetails = () => setInviteDetails(defaultInviteValues);

  enum Modals {
    INVITE_TEAM_MEMBER = 'invite',
    REMOVE_TEAM_MEMBER = 'remove',
    UPLOAD_LOGO = 'uploadLogo',
  }

  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const closeModal = () => {
    resetInviteDetails();
    setOpenModal(null);
    setUserInEditMode(null);
    resetNewRole();
  };

  const [logoHovered, setLogoHovered] = useState<boolean>(false);

  const modalContainerStyles = {
    padding: '3rem',
    backgroundColor: colors.white.main,
    width: breakpointBreached ? '90%' : 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [error, setError] = useState<string | null>(null);
  const [postLoading, setPostLoading] = useState<boolean>(false);
  const [showCopySuccessAlert, setShowCopySuccessAlert] = useState<boolean>(false);

  const orgDetailsInit: UpdateOrgBody = useMemo(() => ({
    country: org?.country,
    state: org?.state,
    city: org?.city,
    phone: org?.phone,
    contact_email: org?.contact_email,
    point_of_contact: org?.point_of_contact,
    use_pros: org?.use_pros,
  }), [org]);

  const [orgDetails, setOrgDetails] = useState<UpdateOrgBody>(orgDetailsInit);
  const updateOrgDetails = (key: keyof UpdateOrgBody, val: string) => setOrgDetails({
    ...orgDetails,
    [key]: val,
  });
  const resetOrgDetails = () => setOrgDetails(orgDetailsInit);

  const fieldsAreIncomplete = useMemo(() => Object.values(orgDetails)
    .filter((val) => !val).length > 0, [orgDetails]);
  const disableSaveBtn = fieldsAreIncomplete || !validateEmail(orgDetails.contact_email);

  const effectFired = useRef<boolean>(false);

  // once the org has finished loading, set its details into state once, so that the
  // user can edit them if they choose to
  useEffect(() => {
    if (orgLoading || effectFired.current) return;
    effectFired.current = true;
    setOrgDetails(orgDetailsInit);
  }, [orgLoading, orgDetailsInit, effectFired]);

  useEffect(() => {
    if (!org) return;
    setOrgDetails({
      country: org.country,
      state: org.state,
      city: org.city,
      phone: org.phone,
      contact_email: org.contact_email,
      point_of_contact: org.point_of_contact,
      use_pros: org.use_pros,
    });
  }, [org]);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const toggleIsEditMode = () => setIsEditMode(!isEditMode);

  const [showMutationOutcomeAlert, setShowMutationOutcomeAlert] = useState(true);

  const setUsePros = async () => {
    await updateOrg({ orgId: org?.org_id, newValues: { use_pros: !orgDetails.use_pros } });
    setLastSuccessfulMutation('updateOrg');
    setShowMutationOutcomeAlert(true);
    setIsEditMode(false);
  }

  const handleSaveOrgChanges = async () => {
    await updateOrg({ orgId: org?.org_id, newValues: orgDetails });
    setLastSuccessfulMutation('updateOrg');
    setShowMutationOutcomeAlert(true);
    setIsEditMode(false);
  };

  const handleCancelOrgChanges = () => {
    setIsEditMode(false);
    resetOrgDetails();
  };

  // autoformatting for typing in the phone input
  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const latestChar = value.split('')[value.length - 1];

    // handle backspace
    if (value.length < (orgDetails.phone?.length || 0)) {
      updateOrgDetails('phone', value);
      return;
    }

    if (isNaN(parseInt(latestChar)) || value.length === 15) return;

    if (value.length === 1) {
      updateOrgDetails('phone', '(' + value);
      return;
    }

    if (value.length === 4) {
      updateOrgDetails('phone', value + ') ');
      return;
    }

    if (value.length === 9) {
      updateOrgDetails('phone', value + '-');
      return;
    }

    updateOrgDetails('phone', value);
  };

  const handleOrgDetailsInputOnChange = (key: keyof UpdateOrgBody, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    switch (key) {
      case 'phone':
        handlePhoneInputChange(e);
        break;
      default:
        updateOrgDetails(key, e.target.value);
        break;
    }
  };

  const adminActionsHeaderAccessor = 'actions';

  const allTableHeaders: TableColumnType[] = useMemo(() => [
    { Header: "Name", accessor: "name", align: "left", isSorted: false, canSort: false },
    { Header: "Email", accessor: "email", align: "left", isSorted: false, canSort: false },
    { Header: "Status", accessor: "status", align: "left", isSorted: false, canSort: false },
    { Header: "Role", accessor: "role", align: "left", isSorted: false, canSort: false },
    { Header: "Remove Team Member", accessor: adminActionsHeaderAccessor, align: "center", isSorted: false, canSort: false },
  ], []);

  // if the user does not have permission to remove team members from the org, we just don't
  // render the "Remove Team Member" column
  const tableHeaders = useMemo(
    () => allTableHeaders.filter((header) => user?.can(DELETE$ROLE) ? header : header.accessor !== adminActionsHeaderAccessor),
    [allTableHeaders, user, DELETE$ROLE]
  );

  const tableData = usersWithRoles?.map((_user) => ({
    name: _user.name,
    email: _user.email,
    status: _user.email_verified ? 'Verified' : 'Invited',
    role: (
      <MDBox {...containerStyles({ gap: '.5rem', alignItems: 'center' })}>
        {(userInEditMode?.mp_id !== _user.mp_id || (userInEditMode?.mp_id === _user.mp_id && openModal)) && (
          // if the a given user on the table is not in edit mode, and that user is not the
          // logged in user (you can't remove yourself from the team or change your own role)
          // show static role field and the edit button, if the logged in user has permission
          // to edit team members' roles, in order to set that user into edit mode.
          <>
            <span style={{ textTransform: 'capitalize' }}>
              {/*
                JAI_ADMIN is the internal only SuperAdmin role. SuperAdmins have access to all permissions that
                regular admins do, but eventually should also be able to edit data across all organizations, for
                example setting the `subscribed` boolean to true on an org after that org has paid to use the platform.
                If you have a SuperAdmin role, we're just showing you as an Admin on the table here.
               */}
              {_user.role === Roles.JAI_ADMIN ? Roles.ADMIN : _user.role}
            </span>
            {_user.mp_id !== user?.details.sub && (
              <IfUserIsAllowed to={UPDATE$ROLE}>
                <FontAwesomeIcon
                  role="button"
                  tabIndex={0}
                  cursor="pointer"
                  icon={faPenToSquare}
                  onClick={() => setUserInEditMode(_user)}
                />
              </IfUserIsAllowed>
            )}
          </>
        )}
        {userInEditMode?.mp_id === _user.mp_id && !openModal && (
          // otherwise if the user *is* in edit mode, show the dropdown to allow thier
          // role to be updated, along with the cancel and save buttons if the mutation
          // request is not loading.
          <>
            <DropdownSelect
              style={{ minWidth: '10rem', backgroundColor: colors.white.main }}
              options={Object.values(Roles)
                // Only SuperAdmins can assign the SuperAdmin role to other users.
                .filter((role) => user?.orgs[0].role === Roles.JAI_ADMIN ? role : !internalOnlyRoles[role])
                .map((role) => ({
                  value: role,
                  handleClick: () => setNewUserRole(role),
                })
                )}
              value={newUserRole || _user.role}
            />
            {updateOrgRoleLoading && <FontAwesomeIcon icon={faCircleNotch} spin size="2x" color={colors.secondary.main} />}
            {!updateOrgRoleLoading && (
              <>
                <FontAwesomeIcon
                  cursor="pointer"
                  size="2x"
                  role="button"
                  tabIndex={0}
                  onClick={async () => {
                    if (!newUserRole) return;
                    await updateOrgRole({
                      orgRoleId: userInEditMode.org_role_id,
                      userId: userInEditMode.mp_id,
                      newValues: { role: newUserRole }
                    });
                    setLastSuccessfulMutation('updateOrgRole');
                    setShowMutationOutcomeAlert(true);
                    setUserInEditMode(null);
                    resetNewRole();
                  }}
                  icon={faCircleCheck}
                  color={colors.success.focus}
                />
                <FontAwesomeIcon
                  cursor="pointer"
                  size="2x"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setUserInEditMode(null);
                    resetNewRole();
                  }}
                  icon={faCircleXmark}
                  color={colors.error.focus}
                />
              </>
            )}
          </>
        )}
      </MDBox>
    ),
    actions: _user.mp_id !== user?.details.sub && (
      <FontAwesomeIcon
        cursor='pointer'
        icon={faX}
        color={colors.error.main}
        role="button"
        tabIndex={0}
        onClick={() => {
          setUserInEditMode(_user);
          setOpenModal(Modals.REMOVE_TEAM_MEMBER);
        }}
      />
    ),
  })) || [];

  // submit handler for creating an invite
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!accessToken || !user) return;

    setError(null);
    setPostLoading(true);

    const resp = await postInvite(
      {
        ...inviteDetails,
        email: inviteDetails.email.trim().toLowerCase(),
        org_id: user.orgs[0].org_id,
      },
      accessToken,
      user,
    ).catch((err: AxiosError) => {
      setError('Something went wrong. Please try again.');
      setPostLoading(false);
      return;
    });

    const invite = resp?.data.invite;

    if (!invite) return;

    const inviteUrl = `${DOMAIN_BASE_URL}${routesMap[RouteKeys.SIGN_UP].route}/?email=${invite.email}&invite=${invite.invite_id}`;

    navigator.clipboard.writeText(inviteUrl);

    setTimeout(() => {
      setShowCopySuccessAlert(false);
    }, 10000);
    setShowCopySuccessAlert(true);
    setPostLoading(false);
    closeModal();
  };

  // content for each modal option
  const modalContent: { [M in Modals]: React.ReactNode } = {
    [Modals.INVITE_TEAM_MEMBER]: (
      <MDBox
        shadow={Shadows.SMALL}
        borderRadius={borders.borderRadius.lg}
        component='form'
        onSubmit={handleSubmit}
        sx={modalContainerStyles}
      >
        <p style={{
          marginBottom: '2rem',
          textAlign: 'center',
          fontWeight: 600,
        }}>
          Invite New Team Member
        </p>
        <MDInput
          dark
          sx={{ marginBottom: '1rem' }}
          type='email'
          label='Email'
          fullWidth
          onChange={(event) => updateInviteDetails('email', event.target.value)}
          value={inviteDetails.email}
        />
        <DropdownSelect
          label='Account Type'
          options={Object.values(Roles)
            .filter((role) => !internalOnlyRoles[role])
            .map((role) => ({
              value: role,
              handleClick: () => updateInviteDetails('role', role),
            })
            )}
          value={inviteDetails.role}
          style={{ marginBottom: '2rem' }}
        />
        <MDButton
          type='submit'
          variant='contained'
          color={MUIColors.SECONDARY}
          disabled={!inviteDetails.email || !validateEmail(inviteDetails.email) || postLoading}
        >
          {postLoading
            ? <FontAwesomeIcon icon={faCircleNotch} color={colors.white.main} spin />
            : 'Generate Invitation Link'
          }
        </MDButton>
        {error && (
          <MDTypography
            variant='body2'
            color={MUIColors.ERROR}
            sx={{
              lineHeight: '16px',
              marginTop: '1rem',
              maxWidth: '13rem',
              textAlign: 'center',
            }}
          >
            {error}
          </MDTypography>
        )}
      </MDBox>
    ),
    [Modals.REMOVE_TEAM_MEMBER]: (
      <MDBox
        shadow={Shadows.SMALL}
        borderRadius={borders.borderRadius.lg}
        sx={modalContainerStyles}
      >
        <p style={{
          marginBottom: '2rem',
          textAlign: 'center',
          fontWeight: 600,
          color: colors.primary.main
        }}>
          Are you sure you want to remove
          <br />
          <span style={{ textTransform: 'capitalize', color: colors.error.main }}>{userInEditMode?.name}</span>
          {' '}
          from
          {' '}
          <span style={{ textTransform: 'capitalize', color: colors.error.main }}>{org?.name}</span>?
        </p>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <MDButton
            variant='contained'
            color={MUIColors.ERROR}
            onClick={async () => {
              if (!userInEditMode) return;
              await deleteOrgRole({ orgRoleId: userInEditMode?.org_role_id, userId: userInEditMode.mp_id });
              setLastSuccessfulMutation('deleteOrgRole')
              setShowMutationOutcomeAlert(true);
              closeModal();
            }}
          >
            {deleteOrgRoleLoading
              ? <FontAwesomeIcon icon={faCircleNotch} color={colors.white.main} spin />
              : 'Confirm'
            }
          </MDButton>
          <MDButton
            variant="outlined"
            color={MUIColors.ERROR}
            onClick={closeModal}
          >
            Cancel
          </MDButton>
        </div>
        {deleteOrgRoleError && (
          <MDTypography
            variant='body2'
            color={MUIColors.ERROR}
            sx={{
              lineHeight: '16px',
              marginTop: '1rem',
              maxWidth: '13rem',
              textAlign: 'center',
            }}
          >
            {error}
          </MDTypography>
        )}
      </MDBox>
    ),
    [Modals.UPLOAD_LOGO]: (
      <MDBox
        shadow={Shadows.SMALL}
        borderRadius={borders.borderRadius.lg}
        sx={modalContainerStyles}
        position='relative'
      >
        <FontAwesomeIcon
          role="button"
          tabIndex={0}
          onClick={() => setOpenModal(null)}
          style={{ position: 'absolute', top: '1rem', right: '1rem', cursor: 'pointer' }}
          icon={faX}
        />
        <MDTypography sx={{ marginBottom: '2rem' }} color={MUIColors.SECONDARY} fontWeight="medium">
          Upload Organization Logo
        </MDTypography>
        <LogoUploadPortal
          org={org}
          handleSuccess={() => setOpenModal(null)}
          handleError={() => setOpenModal(null)}
        />
      </MDBox>
    ),
  };

  return (
    <>
      <Alert show={showCopySuccessAlert} handleClose={() => setShowCopySuccessAlert(false)}>
        Success! Invitation link copied to clipboard.
      </Alert>
      <Alert
        show={showMutationOutcomeAlert && mutationSuccessful}
        handleClose={() => setShowMutationOutcomeAlert(false)}
      >
        {updateOrgSuccessful && lastSuccessfulMutation === 'updateOrg' && 'Organization details updated successfully.'}
        {deleteOrgRoleSuccessful && lastSuccessfulMutation === 'deleteOrgRole' && 'Team member successfully removed.'}
        {updateOrgRoleSuccessful && lastSuccessfulMutation === 'updateOrgRole' && 'Role updated successfully.'}
      </Alert>
      <Alert
        show={showMutationOutcomeAlert && mutationUnsuccessful}
        handleClose={() => setShowMutationOutcomeAlert(false)}
        type="error"
      >
        Something went wrong. Please try again later.
      </Alert>
      {!!openModal && (
        <Modal
          {...containerStyles({
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
          open={!!openModal}
          onClose={closeModal}
        >
          <>{modalContent[openModal]}</>
        </Modal>
      )}
      <Container maxWidth="sm" sx={{ minWidth: "350px" }}>
        <MDAppBar />
        <ProfileContainer
          header={org?.name || '-'}
          icon={false}
          shiftHeaderUp
        >
          <MDBox {...containerStyles({ paddingTop: '1rem', flexDirection: 'column' })}>
            <SectionHeader>
              <MDTypography lineHeight={0} color={MUIColors.SECONDARY} fontWeight="medium">
                Members
              </MDTypography>
              <IfUserIsAllowed to={CREATE$INVITE}>
                <MDButton
                  color={MUIColors.SECONDARY}
                  variant="contained"
                  onClick={() => setOpenModal(Modals.INVITE_TEAM_MEMBER)}
                >
                  Invite Team Member
                </MDButton>
              </IfUserIsAllowed>
            </SectionHeader>
            <DataTable
              table={{ columns: tableHeaders, rows: tableData ? tableData : [] }}
              isSorted={[true, true, true, true,]}
              showTotalEntries={!dataLoading}
              pagination={{ variant: "contained", color: "secondary" }}
              sx={{ padding: "0 2.25rem", float: "left" }}
              dataLoading={dataLoading}
              dataError={!!dataError}
              handleRetry={refetchOrg}
            />
          </MDBox>
          <SectionHeader>
            <MDTypography lineHeight={0} color={MUIColors.SECONDARY} fontWeight="medium">
              Organization Details
            </MDTypography>
            <IfUserIsAllowed to={UPDATE$ORG}>
              {!isEditMode && <FontAwesomeIcon onClick={toggleIsEditMode} cursor='pointer' icon={faEdit} color={colors.secondary.main} />}
              {isEditMode && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
                  {!updateOrgLoading && (
                    <>
                      <FontAwesomeIcon
                        role="button"
                        tabIndex={0}
                        onClick={disableSaveBtn ? () => { } : handleSaveOrgChanges}
                        icon={faCircleCheck}
                        cursor={disableSaveBtn ? 'default' : 'pointer'}
                        color={disableSaveBtn ? colors.grey[500] : colors.success.focus}
                      />
                      <FontAwesomeIcon
                        role="button"
                        tabIndex={0}
                        onClick={handleCancelOrgChanges}
                        cursor='pointer'
                        icon={faCircleXmark}
                        color={colors.error.focus}
                      />
                    </>
                  )}
                  {updateOrgLoading && <FontAwesomeIcon icon={faCircleNotch} spin color={colors.secondary.main} />}
                </div>
              )}
            </IfUserIsAllowed>
          </SectionHeader>
          <MDBox
            {...containerStyles({
              marginBottom: '1rem',
              padding: '0 1rem',
              flexDirection: 'column',
              gap: '.5rem',
              display: 'flex',
            })}
          >
            <MDBox {...containerStyles({
              flexDirection: 'column',
              gap: '.25rem',
              borderBottom: `1px solid ${colors.grey[300]}`,
              paddingBottom: '1rem',
              display: 'grid',
              gridTemplateColumns: '.2fr 1fr',
            })}>
              <MDBox>
                <MDTypography textTransform="capitalize" variant="body2" fontWeight="bold">
                  Logo
                  {' '}
                  <Tooltip
                    title={(
                      <MDTypography sx={{ fontSize: '13px' }} color={MUIColors.WHITE}>
                        This information will be present on the patient care report.
                      </MDTypography>
                    )}
                    placement='top'
                  >
                    <FontAwesomeIcon icon={faInfoCircle} color={colors.info.main} />
                  </Tooltip>
                </MDTypography>
                <MDBox
                  role="button"
                  tabIndex={0}
                  onMouseOver={() => setLogoHovered(true)}
                  onMouseLeave={() => setLogoHovered(false)}
                  onClick={user?.can(UPDATE$ORG) ? () => setOpenModal(Modals.UPLOAD_LOGO) : undefined}
                  shadow={Shadows.MEDIUM}
                  {...containerStyles({
                    position: 'relative',
                    width: '5rem',
                    height: '5rem',
                    borderRadius: '8px',
                    border: `1px solid ${colors.grey[500]}`,
                    backgroundColor: colors.grey[200],
                    cursor: user?.can(UPDATE$ORG) ? 'pointer' : 'default',
                    overflow: 'hidden',
                  })}
                >
                  {logoHovered && user?.can(UPDATE$ORG) && (
                    <FontAwesomeIcon
                      style={{
                        position: 'absolute',
                        top: '.5rem',
                        left: '.5rem',
                        opacity: .8,
                      }}
                      icon={faPenToSquare}
                      color={colors.grey[500]}
                    />
                  )}
                  {org?.logo_url && (
                    <img
                      src={org.logo_url}
                      alt={`${org.name} Logo`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  )}
                </MDBox>
              </MDBox>

              {triageFlow && (
                <div>
                  <IfUserIsAllowed to={CREATE$INVITE}>
                    <Typography fontWeight="bold" color={MUIColors.SECONDARY}>Is your clinic collecting PROs?</Typography>
                    <Switch
                      onClick={setUsePros}
                      onLabel='Yes'
                      offLabel='No'
                      isOn={orgDetails.use_pros ?? false} />
                  </IfUserIsAllowed>
                </div>
              )}

            </MDBox>
            {Object.entries(orgDetails)
              .filter(entry => !['use_pros']
                .includes(entry[0])).map(([key, val], i, a) => (
                  <div key={key} style={{ height: '4rem', borderBottom: i !== a.length - 1 && !isEditMode ? `1px solid ${colors.grey[300]}` : 'none' }}>
                    <MDTypography textTransform="capitalize" variant="body2" fontWeight="bold">
                      {(key === 'phone' || key === 'contact_email' || key === 'point_of_contact') && 'Patient '}
                      {key === 'phone' && 'Contact '}
                      {key.replaceAll('_', ' ')}
                      {(key === 'phone' || key === 'contact_email' || key === 'point_of_contact') && (
                        <>
                          {' '}
                          <Tooltip
                            title={(
                              <MDTypography sx={{ fontSize: '13px' }} color={MUIColors.WHITE}>
                                This information will be present on the patient care report.
                              </MDTypography>
                            )}
                            placement='top'
                          >
                            <FontAwesomeIcon icon={faInfoCircle} color={colors.info.main} />
                          </Tooltip>
                        </>
                      )}
                    </MDTypography>
                    {!isEditMode && (
                      <MDTypography textTransform={key === 'contact_email' ? 'none' : "capitalize"} variant="body2" fontWeight="regular">
                        {val || 'N/A'}
                      </MDTypography>
                    )}
                    {isEditMode && (
                      <MDInput
                        dark
                        type={key === 'contact_email' ? 'email' : 'text'}
                        fullWidth
                        error={!val || (key === 'contact_email' && !validateEmail(val))}
                        value={val}
                        onChange={(e) => handleOrgDetailsInputOnChange(key as keyof UpdateOrgBody, e)}
                      />
                    )}
                  </div>
                ))}
          </MDBox>
        </ProfileContainer>
        <Footer />
      </Container >
    </>
  );
}

export default OrganizationPage;
