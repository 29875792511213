import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import { containerStyles, getJointFromFullJoint } from 'helpers/helpers';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { surveys } from 'data/surveys';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import {
  JointSurveyData,
  NewPRO,
  PRO,
  QuestionTypes,
  StaticStep,
  SurveyQuestion,
  SurveySection,
  SurveySeries,
  SurveyTypes,
} from 'models/SurveyModels';
import { FullJoints } from 'models/XrayModels';
import Moment from "moment";
import { useActivePatient } from 'hooks/useActivePatient';
import { useBreakpoints } from 'hooks/useBreakpoints';
import colors from 'assets/theme/base/colors';
import { RouteKeys } from 'models/RouteModels';
import SurveyJointSelection from 'components/organisms/SurveyJointSelection/SurveyJointSelection';
import SurveyJointRanking from 'components/organisms/SurveyJointRanking/SurveyJointRanking';
import SurveyComplete from 'components/organisms/SurveyComplete/SurveyComplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useProMutations } from 'hooks/useProMutations';
import { useNavigation } from 'hooks/useNavigation';
import { useLocation } from 'react-router-dom';
import { AppointmentRankedJoints } from 'models/AppointmentModels';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import { useProData } from 'hooks/useProData';
import MultiScreenSurveyInterface from 'components/organisms/MultiScreenSurveyInterface/MultiScreenSurveyInterface';
import SingleScreenSurveyInterface from 'components/organisms/SingleScreenSurveyInterface/SingleScreenSurveyInterface';
import { useAuth } from 'hooks/useAuth';
import ActionSlugs from 'services/PermissionsService';

const SurveyEntryPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // joint to render survey for
  const fullJoint = queryParams.get('joint')?.replace('-', ' ') as FullJoints | null;
  // which view to render between desktop and tablet
  const view = queryParams.get('view');
  // if editing an existing PRO, that PRO's ID
  const proId = queryParams.get('pro');
  // if the user is attempting to edit a PRO
  const canEdit = !!parseInt(queryParams.get('edit') || '0');

  const { user } = useAuth();
  const { UPDATE$PRO } = ActionSlugs;

  const viewModes: { [key: string]: 'desktop' | 'tablet' } = {
    desktop: 'desktop',
    tablet: 'tablet',
  };

  const includeJointSelectAndRank = !fullJoint;
  // if the value of the view query param is not a valid member of viewModes,
  // just default to desktop view
  const mode = view && viewModes[view] ? viewModes[view] : 'desktop';
  const isPatientInputMode = mode === 'tablet';
  const isStaffInputMode = mode === 'desktop';

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const queryOpts = { preventRefetch: true };

  const {
    appointment,
    appointmentLoading,
    appointmentError,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    ...queryOpts,
  });

  const {
    updateAppointment,
    updateAppointmentLoading,
    updateAppointmentError,
  } = useAppointmentMutations();

  const {
    createPro,
    createProError,
    createProLoading,
    updatePro,
    updateProLoading,
    updateProError,
    createMultiplePros,
    createMultipleProsError,
    createMultipleProsLoading,
  } = useProMutations({ appointment });

  const {
    latestProsMappedToJoints,
    allAppointmentProsLoading,
    pro,
    proError,
    proLoading,
  } = useProData({ proId, appointment });

  const noProsExistYet = !latestProsMappedToJoints
    || !Object.values(latestProsMappedToJoints).length;

  const showResults = !!proId && !!pro;

  const isIncomplete = !!pro && !pro.scores;

  const { activePatient } = useActivePatient();

  const dataLoading = appointmentLoading || proLoading || allAppointmentProsLoading;

  const appointmentOrSurveyError = (
    !appointment
    || appointmentError
    || !appointment.survey_type
    || !appointment.joints
    || !surveys[appointment.survey_type] // TODO: handling for non hoos/koos surveys (not in MVP)
    || proError
  );

  const mutationLoading = updateAppointmentLoading || createMultipleProsLoading || updateProLoading || createProLoading;
  const mutationError = createMultipleProsError || updateAppointmentError || updateProError || createProError;

  // this function formats the questions from the selected surveys into the format
  // expected by the backend when the survey is posted. you'll see below that this
  // is used to initialize the activeSurveys state object.
  const generateResponsesObject = (fullJoint: FullJoints) => Object.values(surveys[SurveyTypes.HOOS_KOOS][getJointFromFullJoint(fullJoint)])
    .reduce((obj, surveySect) => {
      obj[surveySect.sectionKey] = surveySect.questions.reduce((obj2, question) => {
        obj2[question.questionKey] = null;
        return obj2;
      }, {} as { [questionKey: string]: null });
      return obj;
    }, {} as { [sectionKey: string]: { [questionKey: string]: null } });

  // if surveying a specific joint per the query params, load that survey into state
  // by default
  const [activeSurveys, setActiveSurveys] = useState<SurveySeries | null>(
    fullJoint
      ? {
        [fullJoint]: {
          survey: {
            surveyKey: fullJoint,
            surveyTitle: fullJoint,
            sections: surveys[SurveyTypes.HOOS_KOOS][getJointFromFullJoint(fullJoint)],
          },
          responses: generateResponsesObject(fullJoint),
        },
      }
      : null
  );

  const [appointmentJoints, setAppointmentJoints] = useState<AppointmentRankedJoints | undefined>(appointment?.joints);

  // setting the appointment joint details into state. we need to do this instead of
  // just using the appointment.joints value directly because, in tablet view when
  // a patient is filling out the surveys themselves, they're asked to confirm which
  // joints they want examined, and thus can edit selections to be different that what
  // the staff member selected when starting the exam.
  useEffect(() => {
    // if appointment hasn't loaded do nothing
    if (dataLoading || !appointment?.joints) return;

    // otherwise, set the appointment joints into state so the
    // patient can change them if necessary
    setAppointmentJoints(appointment.joints);
    // eslint-disable-next-line
  }, [appointment, dataLoading]);

  // setting the survey data into state based upon the `appointmentJoints`
  // state variable. again, because the joint selection can be changed by
  // the patient when in tablet mode, we need to update the survey information
  // based on that instead of just strictly on the appointment.joints value
  useEffect(() => {
    // if appointmentJoints hasn't populated or if we're only surveying
    // for a single specific joint, do nothing.
    if (!appointmentJoints || dataLoading || (fullJoint && !showResults)) return;

    // if we're showing results for an existing PRO, populate the patient
    // responses into state
    if (showResults) {
      setActiveSurveys({
        [pro.joint as FullJoints]: {
          survey: {
            surveyKey: pro.joint,
            surveyTitle: pro.joint,
            sections: surveys[SurveyTypes.HOOS_KOOS][getJointFromFullJoint(pro.joint as FullJoints)],
          },
          responses: pro.survey,
        },
      });
      return;
    }

    // otherwise, populate fresh hoos + koos surveys
    // for each joint being evaluated in the appointment
    setActiveSurveys(
      Object.entries(appointmentJoints)
        .filter(([, rank]) => typeof rank === 'number') // filtering out joints where rank === null
        .map(([fj,]) => fj)
        .reduce((obj, fj) => {
          obj[fj as FullJoints] = {
            survey: {
              surveyKey: fj,
              sections: surveys[SurveyTypes.HOOS_KOOS][getJointFromFullJoint(fj as FullJoints)],
            },
            responses: pro || latestProsMappedToJoints[fj as FullJoints]
              ? pro?.survey || latestProsMappedToJoints[fj as FullJoints]!.survey // if a PRO exists, populate the existing responses
              : generateResponsesObject(fj as FullJoints), // otherwise generate an empty responses object
          };
          return obj;
        }, {} as SurveySeries)
    );
    // eslint-disable-next-line
  }, [
    dataLoading,
    pro,
    fullJoint,
    showResults,
    appointmentJoints,
  ]);

  // helper to check if the appointmentJoints state object is the same as the appointment.joints value.
  // if the patient hasn't made any changes to which joints they want examined, we use this to avoid making
  // an unecessary patch request to update the appointment.
  const checkAppointmentJointDiff = () => {
    if (!appointment || !appointment.joints || !appointmentJoints) return false;
    return !!(
      Object.entries(appointmentJoints).find(([fj, rank]) => !appointment.joints![fj as FullJoints] || appointment.joints![fj as FullJoints] !== rank)
      || Object.entries(appointment.joints).find(([fj, rank]) => !appointmentJoints[fj as FullJoints] || appointmentJoints[fj as FullJoints] !== rank)
    );
  };

  const surveysToPost: NewPRO[] = Object.entries(activeSurveys || {}).reduce((arr, [fj, surveyData]) => {
    arr.push({
      appointment_id: appointment?.appointment_id || '',
      patient_id: activePatient?.patient_id || '',
      survey: surveyData.responses,
      joint: fj as FullJoints,
    });
    return arr;
  }, [] as NewPRO[]);

  const noActiveSurveys = !activeSurveys || !Object.values(activeSurveys).length;

  const questionIsAnswered = (question: SurveyQuestion, surveyKey: FullJoints, _activeSurveys: SurveySeries | null) => {
    if (!_activeSurveys) return false;
    if (!_activeSurveys[surveyKey]?.responses[question.parentSectionKey]) return false;
    return _activeSurveys[surveyKey]?.responses[question.parentSectionKey][question.questionKey];
  };

  const toggleProMissingStatus = async (fullJoint: FullJoints) => {
    if (isPatientInputMode || !appointment || !activePatient) return;

    const existingPro = latestProsMappedToJoints[fullJoint];

    if (!existingPro) {
      await createPro({
        appointment_id: appointment.appointment_id,
        patient_id: activePatient.patient_id,
        survey: generateResponsesObject(fullJoint),
        is_missing: true,
        joint: fullJoint,
      });

      returnToApptDashboard()
    } else {
      const updatedPro: PRO = {
        ...existingPro,
        is_missing: !existingPro.is_missing,
      };
      await updatePro(updatedPro);

      if (!existingPro.is_missing) {
        returnToApptDashboard();
      }
    }
  }

  const handleCreateOrUpdatePro = async (fullJoint: FullJoints, surveyData: JointSurveyData) => {
    if (!isPatientInputMode || !appointment || !activePatient) return;

    const existingPro = latestProsMappedToJoints[fullJoint];

    if (!existingPro) {
      await createPro({
        appointment_id: appointment.appointment_id,
        patient_id: activePatient.patient_id,
        survey: surveyData.responses,
        joint: fullJoint,
      });
    } else {
      const updatedPro: PRO = {
        ...existingPro,
        survey: surveyData.responses,
      };
      await updatePro(updatedPro);
    }
  };

  const handleStaffModeCreateOrUpdatePro = async () => {
    try {
      if (pro) {
        const updatedPro: PRO = {
          ...pro,
          survey: surveysToPost[0].survey,
        };
        await updatePro(updatedPro);
        returnToApptDashboard();
      } else {
        // TODO: this method is outdated as surveys are no longer ever batch posted. refactor to make more
        // sense in the current system (only one survey can be created at a time when in staff mode)
        await createMultiplePros(surveysToPost);
        returnToApptDashboard();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateAppointmentJoints = async () => {
    if (!checkAppointmentJointDiff()) {
      // if the patient has not made changes to the joint selection for the exam, simply move to the next
      // question and do nothing else.
      setActiveQuestionIndex(activeQuestionIndex + 1);
      return;
    } else {
      if (!appointment) return;
      // if they have updated the joint selection, first update the appointment object on the db table, and
      // then move to the next question
      await updateAppointment({
        appointmentDate: appointment.appointment_date,
        appointmentId: appointment.appointment_id,
        newValues: {
          joints: appointmentJoints,
        },
      });
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const returnToApptDashboard = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);

  const jointSelectStepSectionKey = 'jointSelectionSect';
  const jointRankingStepSectionKey = 'jointRankingSect';
  const surveyCompleteStepSectionKey = 'surveyCompleteSect';

  // this function dynamically generates the survey sections based on the survey_type
  // field on the appointment. for now, this field will always be HOOs/KOOs Jr.
  const generateSurveySections = (): SurveySection[] => {
    if (!appointment || !appointment.survey_type || !surveys[appointment.survey_type]) return [];

    const surveyQuestions = surveys[appointment.survey_type];

    if (!surveyQuestions) return [];

    const jointSelectionQuestion: StaticStep = {
      type: QuestionTypes.STATIC_STEP,
      questionKey: 'jointSelection',
      parentSectionKey: jointSelectStepSectionKey,
      handleNext: (_activeQuestionIndex: number) => setActiveQuestionIndex(_activeQuestionIndex + 1),
      getNextDisabledState: () => noActiveSurveys,
      renderComponent: () => (
        <SurveyJointSelection
          appointment={appointment}
          appointmentJoints={appointmentJoints}
          setAppointmentJoints={setAppointmentJoints}
        />
      ),
    };

    const jointSelectionSection: SurveySection = {
      questions: [jointSelectionQuestion],
      sectionKey: jointSelectStepSectionKey,
      skip: !includeJointSelectAndRank,
    };

    const jointRankingQuestion: StaticStep = {
      type: QuestionTypes.STATIC_STEP,
      questionKey: 'jointRanking',
      parentSectionKey: jointRankingStepSectionKey,
      handleNext: async () => await handleUpdateAppointmentJoints(),
      getNextDisabledState: () => Object.values(appointmentJoints || {}).find((rank) => !rank) === 0 || updateAppointmentLoading,
      handlePrev: (_activeQuestionIndex: number) => setActiveQuestionIndex(_activeQuestionIndex - 1),
      renderComponent: () => (
        <SurveyJointRanking
          appointmentJoints={appointmentJoints}
          setAppointmentJoints={setAppointmentJoints}
        />
      ),
    };

    const jointRankingSection: SurveySection = {
      questions: [jointRankingQuestion],
      sectionKey: jointRankingStepSectionKey,
      skip: !!(activeSurveys && Object.values(activeSurveys).length === 1) || !includeJointSelectAndRank,
    };

    const surveyCompleteScreen: StaticStep = {
      type: QuestionTypes.STATIC_STEP,
      questionKey: 'surveyComplete',
      parentSectionKey: surveyCompleteStepSectionKey,
      handleNext: () => returnToApptDashboard(),
      nextText: 'Back to Appointment',
      renderComponent: () => <SurveyComplete />,
    };

    const surveyCompleteSection: SurveySection = {
      questions: [surveyCompleteScreen],
      sectionKey: surveyCompleteStepSectionKey,
      skip: isStaffInputMode,
    };

    return [
      jointSelectionSection,
      jointRankingSection,
      // Sections for each active survey per the selected joints
      ...Object.entries(activeSurveys || {})
        .sort((a, b) => ((appointment.joints && appointment.joints[a[0] as FullJoints]) || 1) - ((appointment.joints && appointment.joints[b[0] as FullJoints]) || 1))
        .map(([fj, surveyData], surveyIdx, surveyArr) => {
          return surveyData.survey.sections.map((sect, sectIdx, sectArr) => ({
            ...sect,
            questions: sect.questions.map((q, qIdx, qArr) => ({
              ...q,
              parentSurveyKey: fj,
              nextText: qIdx === qArr.length - 1 && sectIdx === sectArr.length - 1 && surveyIdx === surveyArr.length - 1
                ? 'Finish'
                : 'Next',
              handleNext: async (_activeQuestionIndex: number) => {
                await handleCreateOrUpdatePro(fj as FullJoints, surveyData)
                setActiveQuestionIndex(_activeQuestionIndex + 1);
              },
              getNextDisabledState: (_activeSurveys: SurveySeries) => (
                mutationLoading
                || (qIdx === qArr.length - 1 && sectIdx === sectArr.length - 1 && surveyIdx === surveyArr.length - 1
                  ? createMultipleProsLoading || !questionIsAnswered(q, fj as FullJoints, _activeSurveys)
                  : !questionIsAnswered(q, fj as FullJoints, _activeSurveys))
              ),
              handlePrev: (_activeQuestionIndex: number) => setActiveQuestionIndex(_activeQuestionIndex - 1),
            })),
            parentSurveyKey: fj,
            sectionLabel: (
              <MDTypography textAlign='center' variant='h3'>
                <span style={{ fontWeight: 600, color: colors.secondary.main }}>{fj}</span> Questions
              </MDTypography>
            ),
          }));
        })
        .flat(),
      surveyCompleteSection,
    ].filter((section) => !section.skip);
  };

  const sections = generateSurveySections();

  return (
    <Container maxWidth="md" sx={{ minWidth: "350px" }}>
      <MDBox {...containerStyles({
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      })}>
        <MDBox
          sx={{
            display: 'flex',
            flexDirection: breakpointBreached ? 'column' : 'row',
            justifyContent: 'space-between',
            padding: '1rem 2rem',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            marginBottom: '2rem',
          }}
          bgColor={MUIColors.SECONDARY}
          borderRadius="lg"
          coloredShadow="secondary"
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}>
            <MDTypography
              variant='body1'
              color={MUIColors.WHITE}
            >
              Patient: <span style={{ fontWeight: 600 }}>{activePatient?.first_name || '-'} {activePatient?.last_name || ''}</span>
            </MDTypography>
            <MDTypography
              variant='h2'
              color={MUIColors.WHITE}
            >
              PRO Survey
            </MDTypography>
          </div>


          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',
            marginTop: breakpointBreached ? '1rem' : 0,
          }}>
            <MDTypography
              variant='body2'
              color={MUIColors.WHITE}
            >
              <span style={{ fontWeight: 600 }}>Date of Birth: </span>{activePatient?.date_of_birth || '-'}
            </MDTypography>
            <MDTypography
              variant='body2'
              color={MUIColors.WHITE}
            >
              <span style={{ fontWeight: 600 }}>Appointment Date: </span>{appointment ? Moment(new Date(appointment.appointment_date)).format('YYYY-MM-DD, hh:mm A') : '-'}
            </MDTypography>
            <MDTypography
              variant='body2'
              color={MUIColors.WHITE}
            >
              <span style={{ fontWeight: 600 }}>Today's Date: </span>{Moment(new Date()).format('YYYY-MM-DD')}
            </MDTypography>
          </div>
        </MDBox>
        {dataLoading && (
          <FontAwesomeIcon
            spin
            icon={faCircleNotch}
            color={colors.grey[400]}
            style={{ padding: '10% 0' }}
          />
        )}
        {!dataLoading && appointmentOrSurveyError && (
          <div
            style={{
              textAlign: 'center',
              padding: '10%',
            }}
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ marginBottom: '2rem' }}
              size='8x'
              color={colors.error.main}
            />
            <MDTypography
              fontWeight='regular'
              color={MUIColors.ERROR}
              variant='h3'
            >
              There was a problem loading this survey.
            </MDTypography>
          </div>
        )}

        {!dataLoading && !appointmentOrSurveyError && (
          <>
            {isPatientInputMode && (
              <MultiScreenSurveyInterface
                sections={sections}
                activeSurveys={activeSurveys}
                setActiveSurveys={setActiveSurveys}
                setActiveQuestionIndex={setActiveQuestionIndex}
                activeQuestionIndex={activeQuestionIndex}
                breakpointBreached={breakpointBreached}
                isFirstTime={noProsExistYet}
                requestsLoading={dataLoading}
                mutationState={{
                  loading: mutationLoading,
                  error: !!mutationError,
                }}
              />
            )}
            {isStaffInputMode && (
              <SingleScreenSurveyInterface
                sections={sections}
                activeSurveys={activeSurveys}
                setActiveSurveys={setActiveSurveys}
                activeQuestionIndex={activeQuestionIndex}
                handleNext={handleStaffModeCreateOrUpdatePro}
                setToMissing={toggleProMissingStatus}
                isMissing={latestProsMappedToJoints[fullJoint!]?.is_missing || false}
                handleBack={returnToApptDashboard}
                breakpointBreached={breakpointBreached}
                readOnlyMode={!user?.can(UPDATE$PRO) || (!canEdit && (showResults && !isIncomplete))}
                mutationState={{
                  loading: mutationLoading,
                  error: !!mutationError,
                }}
              />
            )}
          </>
        )}
      </MDBox>
    </Container>
  );
};

export default SurveyEntryPage;
