import { Container, Label, Slider, Wrapper } from './Switch.styles';

interface ISwitchProps {
    isOn?: boolean;
    onLabel?: string;
    offLabel?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const Switch: React.FC<ISwitchProps> = ({ isOn, onLabel, offLabel, onClick, disabled }) => {
    return (
        <Wrapper onClick={onClick} disabled={disabled}>
            <Container onClick={onClick}>
                <Slider $isActive={isOn} />
                <Label $isActive={isOn}>{onLabel}</Label>
                <Label $isActive={!isOn}>{offLabel}</Label>
            </Container>
        </Wrapper>
    );
};

export default Switch;