import colors from "assets/theme/base/colors";
import MDButton from "components/atoms/MDButton/MDButton";
import SurveyQuestionMainText from "components/atoms/Text/SurveyQuestionMainText";
import { UploadedXraysMappedToViewsObjType } from "components/styled/StyledDashboardComponents";
import {
  fullJointsByExamTypes,
  reqViewsByExam,
  reqViewsByJoint,
} from "data/dataConstants";
import {
  Appointment,
  AppointmentRankedJoints,
  AppointmentTimes,
  TreatmentOutcomes,
} from "models/AppointmentModels";
import { MUIColors } from "models/StyleModels";
import {
  JointSurveyData,
  PRO,
  PainLevels,
  QuestionTypes,
  SurveyQuestion,
  SurveySeries,
} from "models/SurveyModels";
import {
  DegenerationLevels,
  ExamTypes,
  FullJoints,
  JointCompartments,
  JointSections,
  PredictionSection,
  ViewTypeData,
  ViewTypes,
  Xray,
  normalizedJointSections,
} from "models/XrayModels";

// used by the containerStyles function below to prepopulate commonly used styles
const generateContainerStyles = (otherStyles: { [key: string]: any }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  ...otherStyles,
});

// prepopulates and formats commonly used styles into the MUI custom styling format ( { sx: myStyles } )
export const containerStyles = (styles: { [key: string]: any }) => ({ sx: generateContainerStyles(styles) });

// use by the React Query wrapper hooks to generate a query key using a dynamic appointment. see this section
// of the notion documentation for more information on how react query is utilized in the project:
// https://www.notion.so/JointAi-Comprehensive-Documentation-e8b94dcf09064573af647df6557b7bd1?pvs=4#074ef550cfc14c11b1b5488f0d8c5fff
export const generateQueryKeyFromAppt = (key: string, appointment: Appointment | null) => [key, { activeAppointment: appointment }];

// capitalizes a string
export const capitalize = (str: string) => str.split(' ').map((word) => [word[0].toUpperCase(), ...word.slice(1)].join('')).join(' ');

// converts camelCase to kebab-case, used in animationStyles.ts to generate custom animations for MUI
export const camelToKebab = (str: string): string => str.split('').map((char, i) => i === 0 ? char.toLowerCase() : char === char.toUpperCase() && char !== char.toLowerCase() ? `-${char.toLowerCase()}` : char).join('');

// generates titles for the XrayReviewModal component given an xray and a joint side
export const generateCardDetails = (xray: Xray, side: 'left' | 'right') => {
  switch (xray.client_provided_view_type) {
    case "flex":
      return {
        title: `Bilateral PA Standing with Flexion - ${capitalize(side)}`,
      };
    case "nonFlex":
      return {
        title: `Bilateral PA Standing with no Flexion - ${capitalize(side)}`,
      };
    case "kneeCap":
      return {
        title: `Kneecap ${capitalize(side)}`,
      };
    case "apPelvis":
      return {
        title: 'AP',
      };
    case "rightFrog":
      return {
        title: 'Right Lateral',
      };
    case "leftFrog":
      return {
        title: 'Left Lateral',
      };
    default:
      return {
        title: "",
      }
  }
};

// returns a normalized section label agnostic of joint side
export const formatSectionLabel = (section: JointSections) => {
  if (section === JointSections.RIGHT_KNEECAP || section === JointSections.LEFT_KNEECAP) return 'Kneecap';
  if (section === JointSections.RIGHT_MEDIAL || section === JointSections.LEFT_MEDIAL) return 'Medial';
  if (section === JointSections.RIGHT_LATERAL || section === JointSections.LEFT_LATERAL) return 'Lateral';
  if (section === JointSections.RIGHT_ZONE_1 || section === JointSections.LEFT_ZONE_1) return 'Zone 1';
  if (section === JointSections.RIGHT_ZONE_2 || section === JointSections.LEFT_ZONE_2) return 'Zone 2';
  return 'Result';
};

const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

// validates an email string
export const validateEmail = (string?: string) => emailRegex.test(string || '');

// validates a birthday date string
export const validateDateFormat = (dateString: string) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (datePattern.test(dateString)) {
    const [year, month, day] = dateString.split('-');

    const numericYear = parseInt(year, 10);
    const numericMonth = parseInt(month, 10);
    const numericDay = parseInt(day, 10);

    if (
      numericYear >= 1000 && numericYear <= 9999 &&
      numericMonth >= 1 && numericMonth <= 12 &&
      numericDay >= 1 && numericDay <= 31
    ) {
      return true;
    }
  }

  return false;
};

// this function parses an appointment time from an appointment's appointment_date value, returning
// a valid member of the AppointmentTimes enum, or null.
export const getAppointmentTimeFromISOString = (appointmentDateISOString: string | null): AppointmentTimes | null => {
  if (!appointmentDateISOString) return null;

  const timestamp = appointmentDateISOString.split('T')[1];
  const hours = timestamp.split(':')[0];
  const mins = timestamp.split(':')[1];
  const apptKey = hours + mins;

  // the AppointmentTimes enum contains all valid appointment times within a day
  const apptTimeMap = Object.values(AppointmentTimes).reduce((obj, aptTime) => {
    const aptTimeTimestamp = aptTime.split(' ')[0];
    const aptTimeTimePeriod = aptTime.split(' ')[1];
    let aptTimeHr = aptTimeTimestamp.split(':')[0];
    const aptTimeMin = aptTimeTimestamp.split(':')[1];
    if (aptTimeTimePeriod === 'PM') {
      aptTimeHr = (12 + parseInt(aptTimeHr)).toString();
    }
    let key = aptTimeHr + aptTimeMin;
    if (key.length !== 4) {
      key = '0' + key;
    }
    obj[key] = aptTime;
    return obj;
  }, {} as { [key: string]: AppointmentTimes });

  return apptTimeMap[apptKey] || null;
};

// takes a member of the FullJoints enum and returns the side only
export const getJointSideFromFullJoint = (fullJoint: FullJoints): 'right' | 'left' => fullJoint.split(' ')[0] as 'right' | 'left';
// takes a member of the FullJoints enum and returns the joint only
export const getJointFromFullJoint = (fullJoint: FullJoints): 'hip' | 'knee' => fullJoint.split(' ')[1] as 'hip' | 'knee';

// given an array of numbers, returns the highest number from that array
export const highestValFromArray = (array: number[]) => {
  let highestVal = array[0] || 0;

  if (array.length && array.length > 1) {
    array.forEach((val) => {
      if (val && val > highestVal) highestVal = val;
    });
  }

  return highestVal;
};

// used during the patient survey entry mode when a patient reorders the
// pain ranking of their joints. when the patient clicks the X button to
// remove one of the joints from the ranked list, this function will be called
// to shift all joints previously ranked below it up by 1.
export const shiftUpLowerRankedJoints = (
  removedRank: number | null | undefined,
  appointmentJoints: AppointmentRankedJoints,
) => {
  if (!removedRank || !appointmentJoints) return;
  const jointEntries = Object.entries(appointmentJoints || {});
  const higherRankedEntries = jointEntries.filter(([, rank]) => rank && rank > removedRank);
  const higherRankedEntriesAdjusted = higherRankedEntries.map(([fullJoint, rank]) => [
    fullJoint,
    rank ? rank - 1 : 0,
  ]) as [FullJoints, number][];
  return higherRankedEntriesAdjusted.reduce((obj, [fullJoint, rank]) => {
    obj![fullJoint] = rank;
    return obj;
  }, {} as AppointmentRankedJoints);
};

interface ValidationFnArgs {
  viewTypeDataObj: ViewTypeData<ViewTypes>;
  xray: Xray | null | undefined;
}

// given an xray and a desired view type data object, returns true if the given xray's
// view type, as determined by accion via the xray.view_type_results.viewType field,
// matches the given desired view type. Used in the xray upload portal to check whether
// or not an uploaded xray assigned to a specific view type by a user matches the view
// type that accion thinks it is. also used in the xray panel on the appointment overview
// page to display the status of each required xray for the exam.
export const getXrayViewTypeValidationStatus = ({
  viewTypeDataObj,
  xray,
}: ValidationFnArgs) => (
  viewTypeDataObj.key === "kneeCap" && xray
    ? viewTypeDataObj.value.includes(xray.view_type_results.viewType)
    : xray?.view_type_results?.viewType === viewTypeDataObj.value
);

// returns true if the given xray has a view_type_results value from accion
// that is not "unknown" but does not match the given desired view type
export const getXrayViewTypeMismatchStatus = ({
  viewTypeDataObj,
  xray,
}: ValidationFnArgs) => (
  !getXrayViewTypeValidationStatus({ viewTypeDataObj, xray }) && xray?.view_type_results && xray.view_type_results.viewType !== 'unknown'
);

// returns true if the given xray has a view_type_results value from accion
// that does not match the given desired view type, and that accion has determined
// as "unknown" (not a valid xray view type)
export const getXrayUnknownViewTypeStatus = ({
  viewTypeDataObj,
  xray,
}: ValidationFnArgs) => (
  !getXrayViewTypeValidationStatus({ viewTypeDataObj, xray }) && xray?.view_type_results.viewType === 'unknown'
);

// returns the numeric value of a person's age given a birthday datestring
export const getAgeFromDOBString = (dob: string): number => {
  const dobDate = new Date(dob);
  const currentDate = new Date();

  const birthYear = dobDate.getFullYear();
  const currentYear = currentDate.getFullYear();

  let age = currentYear - birthYear;

  if (currentDate < new Date(currentYear, dobDate.getMonth(), dobDate.getDate())) {
    age--;
  }

  return age;
};

// takes an array of xrays and returns an object where those xrays are mapped
// to their client_provided_view_type value as the key
export const mapXraysToViewTypes = (xrays: Xray[] | undefined) => xrays?.reduce((obj, xray) => {
  obj[xray.client_provided_view_type] = xray;
  return obj;
}, {} as UploadedXraysMappedToViewsObjType) || {} as UploadedXraysMappedToViewsObjType;

type MissingXraysObjType = {
  [ET in ExamTypes]?: ViewTypeData<ViewTypes>[];
};

// takes an array of exam types and an array of xrays, and returns an object where
// each key is one of the exam types, and the value is an array of missing view types
// as required by that exam.
export const getMissingXraysByActiveExams = ({
  activeExamTypes,
  xrays,
}: {
  activeExamTypes: ExamTypes[];
  xrays: Xray[];
}) => activeExamTypes.reduce((obj, examType) => {
  // find all xrays where a joint in the active exam is present. we don't want to ignore an xray
  // that includes both knees even if we're only looking at a single knee exam, because that single
  // knee is covered by the xray of both knees
  const xraysByViewType = mapXraysToViewTypes(xrays.filter((xray) => !!fullJointsByExamTypes[examType][xray.joint]));
  const missingXrays = Object.values(reqViewsByExam[examType]).filter((viewTypeData) => (!viewTypeData.isOptional && !xraysByViewType[viewTypeData.key]));
  if (missingXrays.length) obj[examType] = missingXrays;
  return obj;
}, {} as MissingXraysObjType);

// takes an array of FullJoints members per the joints selected for an appointment, as well as an
// array of PROs, and returns an array comprised of FullJoint members that do not have PROs reflected
// in the supplied PROs array
export const getAppointmentJointsWithMissingPros = ({
  activeExaminedJoints,
  pros,
}: {
  activeExaminedJoints: FullJoints[];
  pros: PRO[];
}) => activeExaminedJoints.filter((fullJoint) => {
  const jointPro = pros.find((pro) => (pro?.joint === fullJoint || pro?.survey?.demographics?.joint === fullJoint));
  return !jointPro?.is_missing && (!jointPro || !jointPro.scores);
});

// takes a desired joint and an array of PROs, and returns an array comprised of only PROs for the given
// joint, ordered by most recently created
export const filterProsByJointAndSortByCreationDate = (joint: FullJoints, pros: PRO[] | undefined) => pros?.filter((pro) => !pro?.hide && (pro?.joint === joint || pro?.survey?.demographics?.joint === joint))
  .sort((a, b) => (new Date(b?.created_at) as any) - (new Date(a?.created_at) as any)) || [];

// takes an appointemnt and checks its joints object, then returns an array of active ExamType members based on
// those joints, for use in determining which xray series will be required. for example if the patient is
// having their right knee and both hips evaluated, the function will return: [ExamTypes.SINGLE_KNEE_RIGHT, ExamTypes.BILAT_HIP]
export const getActiveExamTypesFromAppointment = (appointment: Appointment | undefined | null) => {
  const isRKneeEval = !!(appointment?.joints && appointment.joints[FullJoints.RIGHT_KNEE]);
  const isLKneeEval = !!(appointment?.joints && appointment.joints[FullJoints.LEFT_KNEE]);
  const isRHipEval = !!(appointment?.joints && appointment.joints[FullJoints.RIGHT_HIP]);
  const isLHipEval = !!(appointment?.joints && appointment.joints[FullJoints.LEFT_HIP]);
  const isBilatKneeEval = !!isRKneeEval && !!isLKneeEval;
  const isBilatHipEval = !!isRHipEval && !!isLHipEval;

  const activeExamTypes: ExamTypes[] = Object.values(ExamTypes).filter((examType) => {
    switch (examType) {
      case ExamTypes.SINGLE_KNEE_RIGHT:
        return !!isRKneeEval && !isBilatKneeEval;
      case ExamTypes.SINGLE_KNEE_LEFT:
        return !!isLKneeEval && !isBilatKneeEval;
      case ExamTypes.BILAT_KNEE:
        return isBilatKneeEval;
      case ExamTypes.SINGLE_HIP_RIGHT:
        return !!isRHipEval && !isBilatHipEval;
      case ExamTypes.SINGLE_HIP_LEFT:
        return !!isLHipEval && !isBilatHipEval;
      case ExamTypes.BILAT_HIP:
        return isBilatHipEval;
      default: return false;
    }
  });

  return activeExamTypes;
};

interface GenerateQuestionComponentParams {
  question: SurveyQuestion;
  isPatientInputMode: boolean;
  activeSurveys: SurveySeries | null;
  setActiveSurveys: (value: React.SetStateAction<SurveySeries | null>) => void,
  activeSurvey: JointSurveyData | null | undefined;
  breakpointBreached: boolean;
  readOnlyMode?: boolean;
}

// given a survey question, some additional data, the activeSurveys state and setter functions,
// and whether or not the form is in patient or staff input mode, returns the proper UI component
// for the question based on the question type and props
export const generateQuestionComponent = ({
  question,
  isPatientInputMode,
  activeSurveys,
  setActiveSurveys,
  activeSurvey,
  breakpointBreached,
  readOnlyMode,
}: GenerateQuestionComponentParams) => {
  switch (question.type) {
    case QuestionTypes.STATIC_STEP:
      return question.renderComponent();
    case QuestionTypes.MULTI_CHOICE_SELECT_ONE:
      return (
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: isPatientInputMode ? '0' : '2rem',
        }}>
          {question.label && (
            <>
              <SurveyQuestionMainText bold={isPatientInputMode}>
                {question.label}
              </SurveyQuestionMainText>
              <div
                style={{
                  borderBottom: isPatientInputMode ? `2px solid ${colors.primary.main}` : 'none',
                  width: '60%',
                  marginBottom: isPatientInputMode ? '1rem' : '0',
                }}
              />
            </>
          )}
          <div style={{ display: 'flex', flexDirection: isPatientInputMode ? 'column' : 'row' }}>
            {/* TODO: handling for display = 'radio' */}
            {question.options.map((opt) => {
              const { parentSectionKey, questionKey } = question;
              const { value } = opt;
              const userResponse = activeSurvey && activeSurvey.responses && activeSurvey.responses[parentSectionKey]
                ? activeSurvey.responses[parentSectionKey][questionKey]
                : null

              return (
                <MDButton
                  key={value}
                  variant={
                    userResponse && userResponse === value
                      ? 'contained'
                      : 'outlined'
                  }
                  color={MUIColors.PRIMARY}
                  style={{
                    margin: isPatientInputMode ? '.5rem 0' : '0 .5rem',
                    width: !isPatientInputMode && breakpointBreached ? '15vw' : 'unset',
                    fontSize: !isPatientInputMode && breakpointBreached ? '13px' : 'unset',
                  }}
                  disabled={readOnlyMode}
                  // TODO: this will probably need to be further abstracted in the future
                  onClick={() =>
                    activeSurveys && activeSurvey
                      ? setActiveSurveys({
                        ...activeSurveys,
                        [activeSurvey.survey.surveyKey]: {
                          ...activeSurvey,
                          responses: {
                            ...activeSurvey.responses,
                            [parentSectionKey]: {
                              ...activeSurvey.responses[parentSectionKey],
                              [questionKey]: value,
                            },
                          },
                        },
                      })
                      : null
                  }
                >
                  {value}
                </MDButton>
              );
            })}
          </div>
        </div>
      );
    default: return null;
  }
};

export const degenRankings = {
  [DegenerationLevels.ENDSTAGE]: 4,
  [DegenerationLevels.NEAR_ENDSTAGE]: 3,
  [DegenerationLevels.MODERATE]: 2,
  [DegenerationLevels.NORMAL]: 1,
};

// given an array of DegenerationLevels enum members, returns the worst case level
// of degeneration amongst them
export const getWorstCaseDegeneration = (outcomes: DegenerationLevels[] | null) => {
  if (!outcomes) return null;
  if (outcomes.find((outcome) => outcome === DegenerationLevels.ENDSTAGE)) return DegenerationLevels.ENDSTAGE;
  if (outcomes.find((outcome) => outcome === DegenerationLevels.NEAR_ENDSTAGE)) return DegenerationLevels.NEAR_ENDSTAGE;
  if (outcomes.find((outcome) => outcome === DegenerationLevels.MODERATE)) return DegenerationLevels.MODERATE;
  return DegenerationLevels.NORMAL;
};

// this is an important one and is used in several different places across the app. it takes an array of PredictionSections,
// typically derived from flattening an array of xray.prediction_results.sections subarrays, along with a FullJoint member - and
// returns an array of only the sections from the initial array that pertain to the given joint. This is important because many
// xrays are bilateral and contain sections from joints on both the left and right side of the body, but when we are looking at a
// specific full joint in an exam, we only want to use sections from that specific joint to display to the user, as well as for
// use in determining the care pathway. this function ensures that we are only looking at sections relevant given joint. The way
// accion formats their prediction sections is a bit convoluted, so this function is quite useful in parsing out specific sections
// for specific joints if needed.

export const filterAccionPredSectsByFullJoint = (sections: PredictionSection[], fullJoint: FullJoints) => sections.filter((sect) => !!(
  // single joint xrays will have the hip.joint or knee.joint field marked as single. bilateral xrays will have the hip.joint
  // or knee.joint fields marked as "left" or "right" based upon which side that section belongs to. bilateral xrays will
  // also have the sect.joint field set to "left" or "right", so it's included here as a fallback.
  (getJointFromFullJoint(fullJoint) === 'knee' && (sect.knee === getJointSideFromFullJoint(fullJoint) || sect.knee === 'single'))
  || (getJointFromFullJoint(fullJoint) === 'hip' && (sect.hip === getJointSideFromFullJoint(fullJoint) || sect.hip === 'single'))
  || sect.joint === getJointSideFromFullJoint(fullJoint)
));

// takes an array of PredictionSections and returns an array of Degeneration levels from those sections, favoring client selected
// degenerations over accion's selections if applicable. the return value of this function is typically fed to the getWorstCaseDegeneration
// function to determine what the worst case degeneration level is for a given joint across all view types
export const mapClientFavoredPredictionsFromAccionPredSects = (sections: PredictionSection[]) => sections.map((sect) => sect.clientSelectedPrediction || sect.prediction);

// takes an array of xrays along with a FullJoints member, and returns an array of xrays pertaining to the provided joint that display
// the worst case level of degeneration amongst all xrays provided
export const getWorstCaseXrays = (xrays: Xray[], fullJoint: FullJoints): Xray[] => {
  let worstCaseXrays: Xray[] = [];

  xrays.forEach((xray) => {
    const worstCaseCompartmentDegen = getWorstCaseDegeneration(
      mapClientFavoredPredictionsFromAccionPredSects(
        filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint)
      )
    );

    if (!worstCaseCompartmentDegen) return;

    if (worstCaseXrays.length === 0) {
      worstCaseXrays.push(xray);
      return;
    }

    const existingXrayWorstCaseDegen = getWorstCaseDegeneration(
      mapClientFavoredPredictionsFromAccionPredSects(
        filterAccionPredSectsByFullJoint(worstCaseXrays[0].prediction_results.sections, fullJoint)
      )
    );

    if (!existingXrayWorstCaseDegen) return;

    if (degenRankings[worstCaseCompartmentDegen] > degenRankings[existingXrayWorstCaseDegen]) {
      worstCaseXrays = [xray];
      return;
    } else if (degenRankings[worstCaseCompartmentDegen] === degenRankings[existingXrayWorstCaseDegen]) {
      worstCaseXrays.push(xray);
      return;
    }
  });

  return worstCaseXrays;
};

// takes an array of xrays and returns an array of all accion region of interest image IDs
// from the xrays with only unique values. this is used to fetch the ROI images for a given
// xray from accion to display on the exam room joint cards, in the xray comparison modal, etc
export const collectRoiIds = (xrays: Xray[]) => {
  if (!xrays) return;
  const ids: string[] = [];
  xrays.forEach((xray) => {
    if (xray.prediction_results?.leftROI) ids.push(xray.prediction_results.leftROI);
    if (xray.prediction_results?.rightROI) ids.push(xray.prediction_results.rightROI);
    if (xray.prediction_results?.singleROI) ids.push(xray.prediction_results.singleROI);
  });
  return [...new Set(ids.flat())];
};

// takes a PRO score from a completed pro.scores object and returns a color and label for it for
// use on the UI
export const getPROColorAndLabel = (score?: number | null): { color: string; label: string; } => {
  if (!score) {
    return {
      label: "N/A",
      color: colors.grey[600],
    }
  }

  if (score >= 70) return { color: colors.success.focus, label: 'Mild' };
  if (score < 70 && score >= 40) return { color: colors.warning.focus, label: 'Moderate' };
  return { color: colors.error.focus, label: 'Severe' };
};

// takes a member of the DegenerationLevels enum and returns a color for it for use on the UI
export const getDegenColor = (degenLevel: DegenerationLevels | null) => {
  if (!degenLevel) return;

  const degenColors = {
    [DegenerationLevels.ENDSTAGE]: colors.error.focus,
    [DegenerationLevels.NEAR_ENDSTAGE]: colors.error.focus,
    [DegenerationLevels.MODERATE]: colors.warning.focus,
    [DegenerationLevels.NORMAL]: colors.success.focus,
  };

  return degenColors[degenLevel];
};

// given an array of xrays for a joint and a PRO for a joint, returns the appropriate member
// of the TreatmentOutcomes enum
export const getTreatmentRecco = ({
  xrays,
  pro,
}: {
  xrays: Xray[] | undefined;
  pro: PRO | undefined;
}) => {
  if (!xrays || !pro) return null;

  const fullJoint = pro.joint;
  const overallProScore = pro.scores?.overall;
  const jointDegenOutcomes = mapClientFavoredPredictionsFromAccionPredSects(
    filterAccionPredSectsByFullJoint(
      xrays.map((xray) => xray.prediction_results.sections).flat() || [],
      fullJoint,
    ),
  );

  if (!fullJoint || typeof overallProScore !== 'number' || !jointDegenOutcomes) return null;

  const joint = getJointFromFullJoint(fullJoint);

  type DegendsByCompartmentType = {
    [JC in JointCompartments]?: {
      [VT in ViewTypes]?: DegenerationLevels;
    };
  };

  const degensByCompartment: DegendsByCompartmentType = xrays.reduce((obj, xray) => {
    const viewType = xray.client_provided_view_type;
    const sections = filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint);

    sections.forEach((sect) => {
      const normalizedSect = normalizedJointSections[sect.section];
      obj[normalizedSect] = {
        ...obj[normalizedSect],
        [viewType]: sect.clientSelectedPrediction || sect.prediction,
      };
    });

    return obj;
  }, {} as DegendsByCompartmentType);

  const nonNormalCompartments = [];

  for (const compartment in degensByCompartment) {
    const outcomes = degensByCompartment[compartment as JointCompartments];
    if (!outcomes) return;
    if (Object.values(outcomes).find((degen) => degen !== DegenerationLevels.NORMAL)) {
      nonNormalCompartments.push(compartment);
    }
  }

  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);

  if (
    overallProScore >= 70
    && (
      worstCaseDegen === DegenerationLevels.NEAR_ENDSTAGE
      || worstCaseDegen === DegenerationLevels.ENDSTAGE
    )
  ) {

    return TreatmentOutcomes.OP_IF_WORSENS;
  }
  else if (
    // if the overall pro score is less than 70 and the worst case degeneration
    // across sections is near endstage or worse, the patient is a candidate for surgery
    overallProScore < 70
    && (
      worstCaseDegen === DegenerationLevels.NEAR_ENDSTAGE
      || worstCaseDegen === DegenerationLevels.ENDSTAGE
    )
  ) {
    if (
      joint === 'hip' // hips are only eligable for complete replacement surgery
      // if there there is more than one compartment that has a degeneration level worse than normal,
      // the patient is a candidate for total repacement surgery
      || nonNormalCompartments.length > 1
    ) {
      return TreatmentOutcomes.TOTAL;
    } else {
      // if otherwise all compartments are normal aside from the one NES or ES compartment
      // that triggered the surgery, the patient is a candidate for partial replacement surgery
      return TreatmentOutcomes.PARTIAL;
    }
  } else {
    // otherwise the treatment recco is non operative
    return TreatmentOutcomes.NON_OP;
  }
};

export const isAnyJointSurgicalCandidateByXraySections = (xrays: Xray[] | undefined, fullJoint: FullJoints) => {
  if (!xrays) return false;

  const jointSpecificSections = filterAccionPredSectsByFullJoint(xrays
    .filter((xray) => !!xray?.prediction_results?.sections)
    .map((xray) => xray.prediction_results.sections)
    .flat(), fullJoint)

  const jointDegenOutcomes = mapClientFavoredPredictionsFromAccionPredSects(jointSpecificSections);

  if (!jointDegenOutcomes) return false;

  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);

  return worstCaseDegen === DegenerationLevels.ENDSTAGE || worstCaseDegen === DegenerationLevels.NEAR_ENDSTAGE;
}

export const isAnyJointSurgicalCandidateByXrays = (xrays: Xray[] | undefined, fullJoint?: string) => {
  if (!xrays) return false;

  const jointDegenOutcomes = mapClientFavoredPredictionsFromAccionPredSects(
    xrays.filter((xray) => !!xray?.prediction_results?.sections).map((xray) => xray.prediction_results.sections).flat() || [],
  );

  if (!jointDegenOutcomes) return false;

  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);

  return worstCaseDegen === DegenerationLevels.ENDSTAGE || worstCaseDegen === DegenerationLevels.NEAR_ENDSTAGE;
}

export const getXrayOnlyTreatmentRecco = ({
  xrays,
  fullJoint,
}: {
  xrays: Xray[] | undefined;
  fullJoint: FullJoints;
}) => {
  if (!xrays) return null;

  const jointDegenOutcomes = mapClientFavoredPredictionsFromAccionPredSects(
    filterAccionPredSectsByFullJoint(
      xrays.map((xray) => xray.prediction_results.sections).flat() || [],
      fullJoint,
    ),
  );

  if (!jointDegenOutcomes) return null;

  const joint = getJointFromFullJoint(fullJoint);

  type DegendsByCompartmentType = {
    [JC in JointCompartments]?: {
      [VT in ViewTypes]?: DegenerationLevels;
    };
  };

  const degensByCompartment: DegendsByCompartmentType = xrays.reduce((obj, xray) => {
    const viewType = xray.client_provided_view_type;
    const sections = filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint);

    sections.forEach((sect) => {
      const normalizedSect = normalizedJointSections[sect.section];
      obj[normalizedSect] = {
        ...obj[normalizedSect],
        [viewType]: sect.clientSelectedPrediction || sect.prediction,
      };
    });

    return obj;
  }, {} as DegendsByCompartmentType);

  const nonNormalCompartments = [];

  for (const compartment in degensByCompartment) {
    const outcomes = degensByCompartment[compartment as JointCompartments];
    if (!outcomes) return;
    if (Object.values(outcomes).find((degen) => degen !== DegenerationLevels.NORMAL)) {
      nonNormalCompartments.push(compartment);
    }
  }

  const worstCaseDegen = getWorstCaseDegeneration(jointDegenOutcomes);

  if ((
    worstCaseDegen === DegenerationLevels.NEAR_ENDSTAGE
    || worstCaseDegen === DegenerationLevels.ENDSTAGE
  )
  ) {
    if (
      joint === 'hip' // hips are only eligable for complete replacement surgery
      // if there there is more than one compartment that has a degeneration level worse than normal,
      // the patient is a candidate for total repacement surgery
      || nonNormalCompartments.length > 1
    ) {
      return TreatmentOutcomes.TOTAL;
    } else {
      // if otherwise all compartments are normal aside from the one NES or ES compartment
      // that triggered the surgery, the patient is a candidate for partial replacement surgery
      return TreatmentOutcomes.PARTIAL;
    }
  } else {
    // otherwise the treatment recco is non operative
    return TreatmentOutcomes.NON_OP;
  }
};

// takes a FullJoints member and an array of xrays, and returns true if the array of xrays
// does not contain an xray for each required view type as denoted by the given joint
export const isMissingXrayData = (joint: FullJoints, xrays: Xray[] | undefined) => {
  if (!xrays) return true;
  const xrayViewTypes = xrays.map((xray) => xray.client_provided_view_type);
  const requiredViewTypes = Object.keys(reqViewsByJoint[joint]);

  return xrayViewTypes.length < requiredViewTypes.length;
};

// this function receives a FullJoint, finds the xrays which contain that specific joint
// (joint name and side of body) via the Accion prediction results, and then finds the
// region of interest for the given joint in the AP Pelvis view for hips, and the Flexion
// view for knees
export const getThumbnailXray = (fullJoint: FullJoints, xraysForJoint: Xray[]) => {
  if (!xraysForJoint || !xraysForJoint.length) return;

  const jointSide = getJointSideFromFullJoint(fullJoint);
  const joint = getJointFromFullJoint(fullJoint);

  const xraysByRoiByJointSide = xraysForJoint.filter((xray) => {
    if (jointSide === 'right') {
      return xray.prediction_results?.rightROI || xray.prediction_results.singleROI;
    }
    return xray.prediction_results?.leftROI || xray.prediction_results.singleROI;
  });

  if (!xraysByRoiByJointSide || !xraysByRoiByJointSide.length) return;

  const worstDegenLevelByView = xraysForJoint.map((xray) => ({
    clientView: xray.client_provided_view_type,
    worst: getWorstCaseDegeneration(xray.prediction_results.sections.map((section: PredictionSection) => section.prediction))
  }))

  const jointOverallWorstDegenLevel = getWorstCaseDegeneration(worstDegenLevelByView
    .map((xray) => xray.worst as DegenerationLevels));

  const allWorstByView = worstDegenLevelByView.filter((xray) => xray.worst === jointOverallWorstDegenLevel);

  if (allWorstByView.length > 1) {

    if (joint === 'hip') {
      return xraysForJoint
        .find((xray) => xray.client_provided_view_type === ViewTypes.AP_PELVIS);
    }

    const worstViewTypes = allWorstByView.map((xray) => xray.clientView);
    const showNonFlexion = !worstViewTypes.includes(ViewTypes.FLEX)
      && (worstViewTypes.includes(ViewTypes.NON_FLEX)
        || worstViewTypes.includes(ViewTypes.KNEECAP))

    return xraysForJoint
      .find((xray) => xray.client_provided_view_type === (
        showNonFlexion ? ViewTypes.NON_FLEX : ViewTypes.FLEX
      ));
  }

  return xraysForJoint
    .find((xray) => xray.client_provided_view_type === allWorstByView[0].clientView);
};

export const snakeToTitleCase = (str: string): string => str
  .split('_')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  .join(' ')

export const roundProScore = (score?: number) => score ? Math.round(score) : null;

export const getProPainLevel = (score?: number) => {

  if (!score) return null

  switch (true) {
    case score >= 70:
      return PainLevels.MILD;
    case score >= 40:
      return PainLevels.MODERATE;
    default:
      return PainLevels.SEVERE;
  }
};