import React, { useMemo, useState } from 'react';
import {
  DegenerationLevels,
  FullJoints,
  JointSections,
  ViewTypes,
  normalizedViewTypes,
} from 'models/XrayModels';
import MainAppLayout, { MainAppLayoutProps } from 'components/templates/MainAppLayout/MainAppLayout';
import useAppointmentLayoutProps from 'hooks/useAppointmentLayoutProps';
import { RouteKeys } from 'models/RouteModels';
import { useActivePatient } from 'hooks/useActivePatient';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { useNavigation } from 'hooks/useNavigation';
import {
  collectRoiIds,
  containerStyles,
  filterAccionPredSectsByFullJoint,
  getJointFromFullJoint,
  getJointSideFromFullJoint,
  getPROColorAndLabel,
  getTreatmentRecco,
  getXrayOnlyTreatmentRecco,
  isMissingXrayData,
} from 'helpers/helpers';
import { useProData } from 'hooks/useProData';
import { useXrayData } from 'hooks/useXrayData';
import MDBox from 'components/atoms/MDBox/MDBox';
import { Breakpoints, MUIColors, Shadows } from 'models/StyleModels';
import colors from 'assets/theme/base/colors';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Container } from '@mui/material';
import XrayDetailCard, { XrayDetailCardLoader } from 'components/organisms/XrayDetailCard/XrayDetailCard';
import { TreatmentOutcomes } from 'models/AppointmentModels';
import { useXrayMutations } from 'hooks/useXrayMutations';
import XrayReviewModal from 'components/organisms/XrayReviewModal/XrayReviewModal';
import MDButton from 'components/atoms/MDButton/MDButton';
import { useRoiImageData } from 'hooks/useRoiImageData';
import { loadingAnimation } from 'assets/animations/animationStyles';
import ErrorPage from '../ErrorPage/ErrorPage';
import ActionSlugs from 'services/PermissionsService';
import { useAuth } from 'hooks/useAuth';

interface ExamRoomSingleJointPageProps {
  fullJoint: FullJoints;
}

const ExamRoomSingleJointPage: React.FC<ExamRoomSingleJointPageProps> = ({ fullJoint }) => {
  const { breakpointBreached: lgBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });
  const { breakpointBreached: mdBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });

  const joint = getJointFromFullJoint(fullJoint);
  const jointSide = getJointSideFromFullJoint(fullJoint);

  const { navigateToBaseRouteWithAptmtDetails } = useNavigation();

  const { UPDATE$XRAY } = ActionSlugs;

  const { user } = useAuth();

  const {
    appointmentId,
    appointmentDateISOString,
  } = useAppointmentParamsFromQuery();

  const { activePatient } = useActivePatient();

  const queryOpts = {
    options: { preventRefetch: true },
  };

  const {
    appointment,
    appointmentLoading,
    appointmentError,
  } = useAppointmentData({
    selectedDateISOString: appointmentDateISOString,
    appointmentId,
    preventRefetch: queryOpts.options.preventRefetch,
  });

  const {
    latestProsMappedToJoints,
    allAppointmentProsLoading,
    allAppointmentProsError,
  } = useProData({ appointment, ...queryOpts });

  const jointPro = latestProsMappedToJoints[fullJoint];

  const {
    allAppointmentXrays,
    xraysMappedToJoints,
    allAppointmentXraysLoading,
    allAppointmentXraysError,
  } = useXrayData({ appointment, ...queryOpts });

  const isLoading = appointmentLoading || allAppointmentProsLoading || allAppointmentXraysLoading;
  const isError = appointmentError || allAppointmentProsError || allAppointmentXraysError;

  const { batchConfirmXrays, updateXrayLoading } = useXrayMutations({ appointment });

  const jointXrays = xraysMappedToJoints[fullJoint];

  const informationMissing = (!jointPro?.is_missing && !jointPro?.scores) || isMissingXrayData(fullJoint, jointXrays);

  // filters all xray sections down to only ones applicable to this specific joint, and then checks
  // if any of them have not been confirmed by the user
  const allXraysConfirmed = jointXrays && !filterAccionPredSectsByFullJoint(
    jointXrays.map((xray) => xray.prediction_results.sections).flat(),
    fullJoint,
  ).find((sect) => !sect.clientConfirmed);

  const roiIds = useMemo(() => allAppointmentXrays ? collectRoiIds(allAppointmentXrays) : null, [allAppointmentXrays]);

  const { roiImgUrls, roiImagesLoading } = useRoiImageData({
    roiIds,
    options: { preventRefetch: true },
  });

  const [activeXrayID, setActiveXrayID] = useState<string | null>(null);
  const activeXray = jointXrays?.find((xray) => xray.xray_id === activeXrayID);
  const modalOpen = !!activeXray;
  const closeModal = () => setActiveXrayID(null);

  const getProScoreDisplayInfo = (score: number | null) => {
    const parsedScore = score ? parseInt(score.toFixed(0)) : null;

    return getPROColorAndLabel(parsedScore)
  }

  const getProOutcomeCard = (score: number | null, sect: string, i: number, collectionLength: number) => (
    <MDBox
      key={sect}
      {...containerStyles({
        flexDirection: 'row',
        paddingRight: lgBreached ? 0 : i === collectionLength - 1 ? '0' : '.5rem',
        marginBottom: lgBreached ? '.5rem' : 0,
        width: lgBreached ? '100%'
          : sect === 'overall'
            ? '40%'
            : joint === 'knee'
              ? '20%'
              : '30%',
      })}
    >
      <MDBox
        {...containerStyles({
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
          backgroundColor: getProScoreDisplayInfo(score).color,
          color: colors.white.main,
          width: '7.5rem',
          height: sect === 'overall' ? '6rem' : '5rem',
          fontSize: sect === 'overall' ? '3rem' : '2rem',
          marginRight: '.5rem',
          position: 'relative',
          overflow: 'hidden',
        })}
      >
        <div
          style={{
            width: '200%',
            height: '100%',
            position: 'absolute',
            top: '-50%',
            left: '-50%',
            borderRadius: '50%',
            opacity: '.1',
            zIndex: 3,
            backgroundColor: 'white',
          }}
        />

        {
          score === null
            ? '--'
            : parseInt(score.toFixed(0))
        }
      </MDBox>
      <MDBox
        {...containerStyles({
          flexDirection: 'column',
        })}
      >
        <MDTypography
          sx={{ color: colors.grey[800] }}
          variant={sect === 'overall' ? 'h4' : 'h5'}
          textTransform='capitalize'
        >
          {
            sect === 'overall'
              ? <>{sect} {joint} health</>
              : <>{joint} {sect}</>
          }
        </MDTypography>
        <MDTypography
          sx={{ fontWeight: 500, color: getProScoreDisplayInfo(score).color }}
          variant={sect === 'overall' ? 'h4' : 'h5'}
          textTransform='capitalize'
        >
          {getProScoreDisplayInfo(score).label}
        </MDTypography>
      </MDBox>
    </MDBox>
  )

  const proOutcomeCards = jointPro?.is_missing
    ? ["overall", ...Object.keys(jointPro?.survey || {})].map((sect, i, a) => getProOutcomeCard(null, sect, i, a.length))
    : Object.entries(jointPro?.scores || {}).map(([sect, score], i, a) => getProOutcomeCard(score, sect, i, a.length));

  const viewTypeOrders: { [VT in ViewTypes | string]: number } = {
    [ViewTypes.FLEX]: 1,
    [ViewTypes.NON_FLEX]: 2,
    [ViewTypes.KNEECAP]: 3,
    'bilatera_kneecap': 3,
    'unilateral_kneecap': 3,
    [ViewTypes.AP_PELVIS]: 1,
    [ViewTypes.LEFT_FROG]: 2,
    [ViewTypes.RIGHT_FROG]: 2,
  };

  const xrayDetailCards = jointXrays?.sort((a, b) => (
    viewTypeOrders[a.client_provided_view_type || a.view_type_results.viewType] - viewTypeOrders[b.client_provided_view_type || b.view_type_results.viewType]
  )).map((xray) => (
    <XrayDetailCard
      key={xray.xray_id}
      imgSrc={roiImgUrls[xray.prediction_results[`${jointSide}ROI`] || xray.prediction_results.singleROI || ''] || undefined}
      imgLoading={roiImagesLoading}
      label={normalizedViewTypes[xray.client_provided_view_type || xray.view_type_results.viewType]}
      displayVertical={mdBreached}
      handleClickReview={() => setActiveXrayID(xray.xray_id)}
      confirmed={!filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint).find((sect) => !sect.clientConfirmed)}
      compartmentOutcomes={
        filterAccionPredSectsByFullJoint(xray.prediction_results.sections, fullJoint)
          .reduce((obj, sect) => {
            obj[sect.section] = sect.clientSelectedPrediction || sect.prediction;
            return obj;
          }, {} as { [JS in JointSections]?: DegenerationLevels })
      }
    />
  ));

  const treatmentRecco = jointPro?.is_missing
    ? getXrayOnlyTreatmentRecco({ xrays: jointXrays, fullJoint })
    : getTreatmentRecco({
      xrays: jointXrays,
      pro: jointPro,
    });

  interface TreatmentLabelsType {
    knee: { [TO in TreatmentOutcomes]: string };
    hip: { [TO in TreatmentOutcomes]: string };
  }

  const treatmentLabels: TreatmentLabelsType = {
    knee: {
      [TreatmentOutcomes.TOTAL]: 'Operative - TKR',
      [TreatmentOutcomes.PARTIAL]: 'Operative - TKR,  Consider Partial Replacement',
      [TreatmentOutcomes.NON_OP]: 'Non-Operative',
      [TreatmentOutcomes.OP_IF_WORSENS]: 'Surgery if Symptoms Worsen',
    },
    hip: {
      [TreatmentOutcomes.TOTAL]: 'Operative', // hips can only receive total replacements.
      [TreatmentOutcomes.PARTIAL]: 'Operative',
      [TreatmentOutcomes.NON_OP]: 'Non-Operative',
      [TreatmentOutcomes.OP_IF_WORSENS]: 'Surgery if Symptoms Worsen',
    },
  };

  const handlePrevious = () => appointment
    ? navigateToBaseRouteWithAptmtDetails({
      routeKey: RouteKeys.EXAM_ROOM_ALL_JOINTS,
      appointmentId: appointment.appointment_id,
      appointmentDateISOString: appointment.appointment_date,
    })
    : {};

  const { headerItems } = useAppointmentLayoutProps({
    appointment,
    patient: activePatient,
    pageTitle: fullJoint,
  });

  const mainAppLayoutProps: MainAppLayoutProps = {
    headerItems,
    navActions: {
      previous: {
        text: 'Back',
        action: handlePrevious,
      },
      next: {
        disabled: updateXrayLoading || allXraysConfirmed || isLoading,
        text: updateXrayLoading ? 'Please Wait...' : 'Confirm All',
        action: () => batchConfirmXrays(jointXrays || [], fullJoint),
        hide: !user?.can(UPDATE$XRAY),
      },
    },
  };

  if (!isLoading && !appointment) return (
    <ErrorPage code={404} />
  );

  if (isError) return (
    <ErrorPage />
  );

  return (
    <>
      {modalOpen && (
        <XrayReviewModal
          xray={activeXray}
          appointment={appointment}
          fullJoint={fullJoint}
          modalOpen={modalOpen}
          handleClose={closeModal}
          roiImgUrls={roiImgUrls}
        />
      )}
      <MainAppLayout {...mainAppLayoutProps}>
        {!isLoading && informationMissing && (
          <>
            <MDBox {...containerStyles({
              textAlign: 'center',
              padding: '5% 10%',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            })}>
              <MDTypography
                variant='h3'
                sx={{
                  color: colors.grey[600],
                  marginBottom: '2rem',
                }}
              >
                Some required information has not been supplied for this joint.
              </MDTypography>
              <MDTypography
                variant='body1'
                sx={{
                  color: colors.grey[600],
                  marginBottom: '2rem',
                }}
              >
                Please confirm that a PRO has been completed
                <br />
                and all required xrays have been uploaded.
              </MDTypography>
              {appointment && (
                <MDButton
                  variant='outlined'
                  color={MUIColors.SECONDARY}
                  onClick={() => navigateToBaseRouteWithAptmtDetails({
                    routeKey: RouteKeys.APPOINTMENT_OVERVIEW,
                    appointmentId: appointment.appointment_id,
                    appointmentDateISOString: appointment.appointment_date,
                  })}
                >
                  Back to Exam Overview
                </MDButton>
              )}
            </MDBox>
          </>
        )}
        {isLoading && (
          <>
            <MDBox
              {...containerStyles({
                width: '100%',
                textAlign: lgBreached ? 'center' : 'left',
                marginBottom: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
                padding: '.5rem',
                background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${colors.disabled.focus} ${mdBreached ? '20%' : '40%'}, ${colors.disabled.focus} 50%, ${colors.disabled.focus} ${mdBreached ? '80%' : '60%'}, rgba(255,255,255,0) 100%)`,
              })
              }>
              <MDBox {...containerStyles({
                width: 'fit-content',
                flexDirection: 'column',
                gap: '.5rem',
              })}>
                <MDBox
                  sx={{
                    width: '10rem',
                    height: '1rem',
                    borderRadius: '4px',
                    ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
                  }}
                />
                <MDBox
                  sx={{
                    width: '20rem',
                    height: '2.65rem',
                    borderRadius: '4px',
                    ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                width: '10rem',
                height: '1.25rem',
                borderRadius: '4px',
                marginBottom: '.75rem',
                ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
              }}
            />
            <MDBox
              shadow={Shadows.MEDIUM}
              {...containerStyles({
                width: '100%',
                height: '7rem',
                borderRadius: '8px',
                padding: '.5rem',
                marginBottom: '2rem',
                ...loadingAnimation('backgroundColor'),
              })}
            />
            <MDBox
              sx={{
                width: '12rem',
                height: '1.25rem',
                borderRadius: '4px',
                marginBottom: '.75rem',
                ...loadingAnimation('backgroundColor', colors.grey[400], colors.grey[500]),
              }}
            />
            <Container
              {...containerStyles({
                width: '100%',
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill, minmax(${lgBreached ? '100%' : joint === 'hip' ? '25rem' : '20rem'}, 1fr))`,
                gap: '.5rem',
              })}
            >
              {Array.from(new Array(joint === 'knee' ? 3 : 2), (el, i) => (
                <XrayDetailCardLoader key={`cardLoader-${i}`} displayVertical={mdBreached} />
              ))}
            </Container>
          </>
        )}
        {!isLoading && !informationMissing && (
          <>
            {treatmentRecco && (
              <MDBox
                {...containerStyles({
                  width: '100%',
                  textAlign: lgBreached ? 'center' : 'left',
                  marginBottom: '2rem',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  padding: '.5rem',
                  background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${colors.disabled.focus} ${mdBreached ? '20%' : '40%'}, ${colors.disabled.focus} 50%, ${colors.disabled.focus} ${mdBreached ? '80%' : '60%'}, rgba(255,255,255,0) 100%)`,
                })
                }>
                <MDBox {...containerStyles({
                  width: 'fit-content',
                  flexDirection: 'column',
                })}>
                  <MDTypography
                    textTransform='uppercase'
                    color={MUIColors.SECONDARY}
                    fontWeight='bold'
                    variant='body2'
                    textAlign='center'>
                    JointDx Treatment Pathway:
                  </MDTypography>
                  <MDTypography sx={{ color: colors.grey[800] }} variant='h3' fontWeight='regular'>
                    {treatmentLabels[joint][treatmentRecco]}
                  </MDTypography>
                </MDBox>
              </MDBox>
            )}

            <MDBox sx={{
              display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1rem', padding: '1rem 0'
            }} >
              <MDTypography mb={1} textTransform='uppercase' sx={{ color: colors.grey[800] }} fontWeight='bold' variant='body2'>
                Pro Survey Results:
              </MDTypography>

              {jointPro.is_missing && <MDTypography variant='body2' sx={{ color: colors.error.focus }}>
                <b>Disclaimer:</b> Submitting without a Patient’s PRO score might result in an inaccurate care pathway diagnosis.
              </MDTypography>}
            </MDBox>

            <MDBox
              shadow={Shadows.MEDIUM}
              {...containerStyles({
                width: '100%',
                flexDirection: lgBreached ? 'column' : 'row',
                borderRadius: '8px',
                backgroundColor: colors.grey[100],
                padding: '.5rem',
                marginBottom: '2rem',
              })}
            >
              {proOutcomeCards}
            </MDBox>
            <MDTypography mb={1} textTransform='uppercase' sx={{ color: colors.grey[800] }} fontWeight='bold' variant='body2'>
              X-ray Analysis Outcomes:
            </MDTypography>
            <Container
              {...containerStyles({
                width: '100%',
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill, minmax(${lgBreached ? '100%' : xrayDetailCards?.length === 2 ? '25rem' : '20rem'}, 1fr))`,
                gap: '.5rem',
              })}
            >
              {xrayDetailCards}
            </Container>
          </>
        )}
      </MainAppLayout>
    </>
  );
};

export default ExamRoomSingleJointPage;
