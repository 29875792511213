import React from 'react';
import { faChevronLeft, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import { generateQuestionComponent } from 'helpers/helpers';
import { MUIColors } from 'models/StyleModels';
import colors from 'assets/theme/base/colors';
import { SurveyQuestion, SurveySection, SurveySeries } from 'models/SurveyModels';
import { FullJoints } from 'models/XrayModels';
import Switch from 'components/atoms/Switch/Switch';

interface SingleScreenSurveyInterfaceProps {
  sections: SurveySection[];
  activeQuestionIndex: number;
  activeSurveys: SurveySeries | null;
  setActiveSurveys: (value: React.SetStateAction<SurveySeries | null>) => void;
  handleBack(): void;
  handleNext(): void;
  breakpointBreached: boolean;
  mutationState?: {
    loading?: boolean;
    error?: boolean;
  };
  readOnlyMode?: boolean;
  isMissing: boolean;
  setToMissing(fullJoint: FullJoints): void;
}

const SingleScreenSurveyInterface: React.FC<SingleScreenSurveyInterfaceProps> = ({
  sections,
  activeQuestionIndex,
  activeSurveys,
  setActiveSurveys,
  handleBack,
  breakpointBreached,
  handleNext,
  mutationState,
  readOnlyMode,
  setToMissing,
  isMissing
}) => {
  const [proMarkedAsMissing, setProMarkedAsMissing] = React.useState(isMissing);

  const questions = sections.map((sect) => sect.questions.map((q) => ({ ...q, parentSurveyKey: sect.parentSurveyKey }))).flat();
  const activeQuestion: SurveyQuestion | null = questions[activeQuestionIndex] || null;
  const activeSurvey = activeSurveys && activeSurveys[activeQuestion?.parentSurveyKey as FullJoints];
  const disableSection = isMissing || proMarkedAsMissing || readOnlyMode;

  return (
    <MDBox>
      <MDBox
        display="flex"
        justifyContent="flex-start"
        sx={{
          ...(!breakpointBreached ? { position: 'sticky', top: '10px' } : null)
        }}>

        <MDButton
          variant='outlined'
          color={MUIColors.SECONDARY}
          onClick={handleBack}
          startIcon={<FontAwesomeIcon color={colors.secondary.main} icon={faChevronLeft} />}
        >
          Back
        </MDButton>

      </MDBox>
      <MDBox display="grid">
        <MDTypography textAlign='center' variant='h3' mb={2}>
          <span style={{
            fontWeight: 600,
            color: colors.secondary.main,
            textTransform: 'capitalize',
          }}>
            {activeSurvey?.survey.surveyTitle}
          </span>
          {' '}
          Questions
        </MDTypography>

        <MDBox
          display="grid"
          sx={{
            gridTemplateColumns: 'auto auto',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem 0',

            ...(breakpointBreached && {
              display: 'flex',
              flexFlow: 'column-reverse'
            }),
          }}
        >
          <Switch
            onClick={() => {
              setProMarkedAsMissing(!proMarkedAsMissing);
            }}
            onLabel='Yes'
            offLabel='No'
            isOn={!proMarkedAsMissing} />

          <MDTypography sx={{
            fontSize: '1.2rem',
            fontWeight: 600,
            color: colors.secondary.main,
            ...(breakpointBreached && {
              fontSize: 'rem',
            }),
          }}>
            Does this patient have complete PROs
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox pb="3rem">
        {sections.map((sect) => (
          <React.Fragment key={sect.sectionKey}>
            {sect.sectionHeader && (
              <SurveyQuestionMainText bold>
                {sect.sectionHeader}
              </SurveyQuestionMainText>
            )}
            {sect.questions.map((q) => (
              <React.Fragment key={q.questionKey}>
                {generateQuestionComponent({
                  question: q,
                  isPatientInputMode: false,
                  activeSurveys,
                  setActiveSurveys,
                  activeSurvey,
                  breakpointBreached,
                  readOnlyMode: disableSection,
                })}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}

        <MDBox sx={{
          position: 'sticky',
          display: 'flex',
          bottom: '40px',
          justifyContent: 'flex-end',
        }}>

          {mutationState?.error && (
            <MDTypography textAlign='right' mr='1rem' variant='body2' color={MUIColors.ERROR}>
              Something went wrong. Please try again.
            </MDTypography>
          )}

          {proMarkedAsMissing !== isMissing
            ? (
              <MDButton
                variant='contained'
                color={MUIColors.SECONDARY}
                onClick={() => setToMissing(activeQuestion?.parentSurveyKey as FullJoints)}>
                Submit
              </MDButton>)
            : (
              <MDButton
                variant='contained'
                color={MUIColors.SECONDARY}
                onClick={handleNext}
                disabled={
                  disableSection ||
                  !!questions.find((q) => activeSurvey
                    && (
                      !activeSurvey.responses[q.parentSectionKey]
                      || !activeSurvey.responses[q.parentSectionKey][q.questionKey]
                    )
                  )
                }
              >
                {
                  mutationState?.loading
                    ? <FontAwesomeIcon color='white' icon={faCircleNotch} spin />
                    : 'Save'
                }
              </MDButton>
            )
          }
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default SingleScreenSurveyInterface;
