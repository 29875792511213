import React, { useEffect, useRef, useState } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@mui/material';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import SurveyQuestionMainText from 'components/atoms/Text/SurveyQuestionMainText';
import SurveyQuestionSubText from 'components/atoms/Text/SurveyQuestionSubText';
import { containerStyles, generateQuestionComponent } from 'helpers/helpers';
import { MUIColors } from 'models/StyleModels';
import { FullJoints } from 'models/XrayModels';
import JointInfoVisualizer from '../JointInfoVisualizer/JointInfoVisualizer';
import { SurveyQuestion, SurveySection, SurveySeries } from 'models/SurveyModels';
import './MultiScreenSurveyInterface.css';

interface MultiScreenSurveyProps {
  sections: SurveySection[];
  activeSurveys: SurveySeries | null;
  setActiveSurveys: (value: React.SetStateAction<SurveySeries | null>) => void;
  setActiveQuestionIndex: (value: React.SetStateAction<number>) => void;
  activeQuestionIndex: number;
  breakpointBreached: boolean;
  mutationState?: {
    loading?: boolean;
    error?: boolean;
  };
  readOnlyMode?: boolean;
  isFirstTime: boolean;
  requestsLoading: boolean;
}

const MultiScreenSurveyInterface: React.FC<MultiScreenSurveyProps> = ({
  sections,
  activeSurveys,
  setActiveSurveys,
  setActiveQuestionIndex,
  activeQuestionIndex,
  breakpointBreached,
  mutationState,
  readOnlyMode,
  isFirstTime,
  requestsLoading,
}) => {
  const questions = sections.map((sect) => sect.questions.map((q) => ({ ...q, parentSurveyKey: sect.parentSurveyKey }))).flat();

  const [loading, setLoading] = useState<boolean>(true);
  const effectFiredRef = useRef<boolean>(false);

  // if an unfinished PRO exists, set the active question to the first unanswered one only once after requests load
  useEffect(() => {
    if (requestsLoading || effectFiredRef.current || !activeSurveys) return;

    const firstUnanswered = questions.find((question) => (
      activeSurveys[question.parentSurveyKey as FullJoints]?.responses[question.parentSectionKey][question.questionKey] === null
    ));

    const firstUnansweredIdx = firstUnanswered && questions.indexOf(firstUnanswered);

    if (!isFirstTime && firstUnansweredIdx) setActiveQuestionIndex(firstUnansweredIdx);
    effectFiredRef.current = true; // only allow this effect to fire once
    setLoading(false);
    // eslint-disable-next-line
  }, [requestsLoading, isFirstTime, activeSurveys, effectFiredRef]);

  const activeQuestion: SurveyQuestion | null = questions[activeQuestionIndex] || null;
  const activeSection: SurveySection | null = sections.find((sect) => sect.sectionKey === activeQuestion?.parentSectionKey && sect.parentSurveyKey === activeQuestion?.parentSurveyKey) || null;
  const activeSurvey = activeSurveys && activeSurveys[activeQuestion?.parentSurveyKey as FullJoints];

  const isLastQuestion = activeQuestionIndex === questions.length - 1;
  const isPROQuestion = activeQuestion && (
    activeQuestion.parentSurveyKey === FullJoints.LEFT_HIP
    || activeQuestion.parentSurveyKey === FullJoints.RIGHT_HIP
    || activeQuestion.parentSurveyKey === FullJoints.LEFT_KNEE
    || activeQuestion.parentSurveyKey === FullJoints.RIGHT_KNEE
  );

  const getProgressValueAndProps = () => {
    const surveyLength = questions.length;
    const currentQuestion = activeQuestionIndex + 1;
    const progress = currentQuestion / surveyLength * 100;

    let value: number;

    if (currentQuestion === 1) {
      value = 0;
    } else if (currentQuestion === surveyLength) {
      value = 99;
    } else {
      value = Math.floor(progress);
    }

    return { value };
  };

  if (loading) return (
    <MDBox {...containerStyles({
      width: '100%',
      marginBottom: '2rem',
      alignItems: 'center',
    })}>
      <FontAwesomeIcon color={colors.grey[300]} icon={faCircleNotch} spin />
    </MDBox>
  );

  return (
    <>
      {!isLastQuestion && (
        <MDBox {...containerStyles({
          width: '100%',
          marginBottom: '2rem',
          alignItems: 'center',
        })}>
          <progress
            max={100}
            value={getProgressValueAndProps().value}
          />
          <MDTypography variant='body2' sx={{ width: '3rem', textAlign: 'center' }}>
            {getProgressValueAndProps().value}%
          </MDTypography>
        </MDBox>
      )}
      <div style={{ marginBottom: '1rem' }}>
        {activeSection?.sectionLabel && (
          <>
            <span style={{ textTransform: 'capitalize' }}>{activeSection.sectionLabel}</span>
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: breakpointBreached ? 'column-reverse' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: breakpointBreached ? '2rem' : '0'
          }}
        >
          {activeSection?.sectionHeader && (
            <SurveyQuestionMainText>{activeSection.sectionHeader}</SurveyQuestionMainText>
          )}
          {activeSection?.sectionDesc && (
            <SurveyQuestionSubText>{activeSection.sectionDesc}</SurveyQuestionSubText>
          )}
          {activeQuestion && generateQuestionComponent({
            question: activeQuestion,
            isPatientInputMode: true,
            activeSurveys,
            setActiveSurveys,
            activeSurvey,
            breakpointBreached,
            readOnlyMode,
          })}
        </div>
        {!isLastQuestion && isPROQuestion && (
          <JointInfoVisualizer
            mode='marker'
            jointData={{
              [FullJoints.LEFT_HIP]: {
                mark: activeQuestion.parentSurveyKey as FullJoints === FullJoints.LEFT_HIP,
              },
              [FullJoints.RIGHT_HIP]: {
                mark: activeQuestion.parentSurveyKey as FullJoints === FullJoints.RIGHT_HIP,
              },
              [FullJoints.LEFT_KNEE]: {
                mark: activeQuestion.parentSurveyKey as FullJoints === FullJoints.LEFT_KNEE,
              },
              [FullJoints.RIGHT_KNEE]: {
                mark: activeQuestion.parentSurveyKey as FullJoints === FullJoints.RIGHT_KNEE,
              },
            }}
            fadeOutBottomToColor='#f0f2f5'
          />
        )}
      </div>
      <MDBox {...containerStyles({
        justifyContent: 'space-between',
        padding: '3rem 0',
      })}>
        <div style={{ width: '50%', display: 'flex' }}>
          {activeQuestion.handlePrev && (
            <MDButton
              variant='outlined'
              color={MUIColors.SECONDARY}
              onClick={() => activeQuestion.handlePrev && activeQuestion.handlePrev(activeQuestionIndex)}
              disabled={activeQuestion.prevDisabled}
            >
              {activeQuestion.prevText || 'Back'}
            </MDButton>
          )}
        </div>
        <div style={{
          width: '50%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {mutationState?.error && (
            <MDTypography textAlign='right' mr='1rem' variant='body2' color={MUIColors.ERROR}>
              Something went wrong. Please try again.
            </MDTypography>
          )}
          {activeQuestion.handleNext && (
            <MDButton
              variant='contained'
              color={MUIColors.SECONDARY}
              onClick={() => activeQuestion.handleNext && activeQuestion.handleNext(activeQuestionIndex)}
              disabled={activeQuestion.getNextDisabledState && activeQuestion.getNextDisabledState(activeSurveys)}
            >
              {
                mutationState?.loading
                  ? <FontAwesomeIcon color='white' icon={faCircleNotch} spin />
                  : activeQuestion.nextText || 'Next'
              }
            </MDButton>
          )}
        </div>
      </MDBox>
    </>
  );
};

export default MultiScreenSurveyInterface;
