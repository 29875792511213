import styled from 'styled-components';

export const Wrapper = styled.div<{ disabled?: boolean }>`
    background-color: #225E9F;
    padding: 0 .5rem;
    width: fit-content;
    border-radius: 1.5rem;
    cursor: ${props => props.disabled ? 'cursor' : 'pointer'};
`

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: fit-content;
    position: relative;
    height: 2.5rem;
`;

export const Label = styled.div<{ $isActive?: boolean }>`
    padding: 0 .5rem;
    font-size: .9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.$isActive ? '#225E9F' : '#fff'};
    transition: color  .5s ease;
    z-index: 1;
    font-weight: 800;
`;

export const Slider = styled.div<{ $isActive?: boolean }>` 
    position: absolute;
    background-color: #E4EEF7;
    width: 50%;
    border-radius: 1.5rem;
    top: 15%;
    height: 70%;
    transform: ${props => props.$isActive ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform  .5s ease;
`;