import { FullJoints } from "./XrayModels";

// TODO: ultimately these types will be defined by the user and saved to DB with
// a corresponding ID which will be saved to the appointment, and then dynamically
// NOT MVP!

// rendered when the survey form is opened for a given appointment
export enum SurveyTypes {
  HOOS_KOOS = 'HOOs/KOOs Jr',
  // NEW_PATIENT_INTAKE = 'New Patient Intake', // not for MVP
}

export enum QuestionTypes {
  STATIC_STEP = 'staticStep',
  MULTI_CHOICE_SELECT_ONE = 'multiChoiceSelectOne',
  MULTI_CHOICE_SELECT_MANY = 'multiChoiceSelectMany',
  MANUAL_ENTRY = 'manualEntry',
}

export enum PainLevels {
  NONE = 'none',
  MILD = 'mild',
  MODERATE = 'moderate',
  SEVERE = 'severe',
  EXTREME = 'extreme',
}

type Question = {
  questionKey: string;
  parentSectionKey: string;
  parentSurveyKey?: string;
  skip?: boolean;
  handleNext?(_activeQuestionIndex?: number): void;
  getNextDisabledState?(_activeSurveys?: SurveySeries | null): boolean;
  nextText?: string;
  handlePrev?(_activeQuestionIndex?: number): void;
  prevDisabled?: boolean;
  prevText?: string;
}

export type StaticStep = {
  type: QuestionTypes.STATIC_STEP;
  label?: string;
  renderComponent(props?: any): React.ReactNode;
} & Question;

export interface MultipleChoiceOption {
  value: any,
}

export type MultipleChoiceQuestion<QT extends QuestionTypes> = {
  type: QT,
  label?: string,
  options: MultipleChoiceOption[];
  display: 'buttons' | 'radio'; // TODO: the radio input method is not currently used, and is thus not currently built
} & Question;

export type MultiChoiceSelectOneQuestion = MultipleChoiceQuestion<QuestionTypes.MULTI_CHOICE_SELECT_ONE>
export type MultiChoiceSelectManyQuestion = MultipleChoiceQuestion<QuestionTypes.MULTI_CHOICE_SELECT_MANY>

export type SurveyQuestion = StaticStep
  | MultiChoiceSelectOneQuestion
  | MultiChoiceSelectManyQuestion

export interface SurveySection {
  parentSurveyKey?: string;
  sectionKey: string;
  sectionLabel?: string | React.ReactNode;
  sectionHeader?: string;
  sectionDesc?: string;
  questions: SurveyQuestion[];
  skip?: boolean;
}

export type Survey = {
  surveyKey: string;
  surveyTitle?: string | React.ReactNode;
  sections: SurveySection[];
}

export type SurveySeries = { [K in FullJoints]?: JointSurveyData };

// Required format for backend parsing:
// { sectionKey: { questionKey: response } }
export interface SurveyResponses {
  [sectionKey: string]: { [questionKey: string]: string | number | null };
}

export interface JointSurveyData {
  survey: Survey;
  responses: SurveyResponses;
}

// -------

export interface FormValuesType {
  [key: string]: { [key: string]: any } | null;
}

export interface FormQuestion {
  questionKey: string;
  label: string;
  entries?: string[];
  details: { [key: string]: string | string[] | FormQuestion[] }
}

export interface KneeScores {
  stiffness: number;
  pain: number;
  function: number;
  overall: number;
}

export interface HipScores {
  pain: number;
  function: number;
  overall: number;
}

// Required format for backend parsing:
// { sectionKey: { questionKey: response } }
export interface PROSurvey {
  [key: string]: { [key: string]: string | number | null };
}

export type NewPRO = {
  appointment_id: string;
  patient_id: string;
  survey: PROSurvey;
  joint: FullJoints;
  is_missing?: boolean;
}

export type PRO = {
  pro_id: string;
  created_at: string;
  updated_at: string;
  scores: KneeScores | HipScores | null;
  hide: boolean;
  user_committed: boolean;
} & NewPRO;
